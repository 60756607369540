/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Box, Typography, styled } from '@mui/material';

const StyledUnorderedList = styled('ul')(({ theme }) => ({
  paddingInlineStart: theme.spacing(3),
  marginTop: theme.spacing(1.25),
  listStyleType: 'disc',
}));

const StyledOrderedList = styled('ol')(({ theme }) => ({
  paddingInlineStart: theme.spacing(3),
  marginTop: theme.spacing(1.25),
  listStyleType: 'decimal',
}));

const StyledListItem = styled('li')(({ theme }) => ({
  lineHeight: 1.5,
  marginBottom: theme.spacing(1.25),
  alignItems: 'center',
}));

const StyledParagraph = styled('div')({
  //margin: theme.spacing(1.25),
  display: 'inline',
});

const ResponseAvatar = styled(Box)({
  padding: 1,
  width: 32,
  height: 32,
  fontSize: '14px',
});

const ErrorMessage = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

const MessageTime = styled(Typography)(({ theme }) => ({
  fontSize: `.5em`,
}));

const MessageText = styled(Typography)(({ theme }) => ({
  overflowWrap: 'anywhere',
  width: 'fit-content',
  [theme.breakpoints.down('md')]: {
    fontSize: `16px`,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: `14px`,
  },
}));

export {
  StyledOrderedList,
  StyledUnorderedList,
  StyledListItem,
  StyledParagraph,
  ResponseAvatar,
  ErrorMessage,
  MessageTime,
  MessageText,
};
