/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import * as React from 'react';

const AiAssistantAvatarSvg = (props) => (
  // replace this with customer provided ai avatar
  <svg
    width={36}
    height={37}
    viewBox="0 0 36 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.000976562}
      y={0.669678}
      width={35}
      height={35}
      rx={18.5}
      fill="#EF4F26"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2959 23.6187L14.3579 23.5982L14.4871 20.8892L10.3036 20.9035L8.2959 23.6187Z"
      fill="#F3F7FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7137 13.5637L13.7266 16.3035L19.2759 31.3212L21.3061 28.6983L19.5076 23.8298L18.4166 20.8767L15.7137 13.5637Z"
      fill="#F3F7FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4457 17.2572L14.7764 14.8373L20.7338 7.01782L21.2526 10.9676L16.4457 17.2572Z"
      fill="#F3F7FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5027 23.7971L28.7053 20.8276L20.4141 20.8563L21.5071 23.8094L26.5027 23.7971Z"
      fill="#F3F7FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4356 14.864L23.9065 10.9163L19.0667 17.3187L19.0605 17.3208L19.0626 17.3249L19.0605 17.329L19.0626 17.3269L20.1864 20.4318L23.0247 16.7055L23.0329 16.7035L24.4356 14.864Z"
      fill="#F3F7FD"
    />
  </svg>
);

export default AiAssistantAvatarSvg;
