/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '#contexts/userContext';
import * as path from '#constants/uiPaths.constant';
import DataGridTable from '#components/datagridTable';
import { roleAndPermissionApi } from '#services/apis';
import ConfirmationDialog from '#components/confirmationDialog';
import { format, parseISO } from 'date-fns';
import { useToastContext } from '#contexts/providers/toast.provider';

const RolesPage = () => {
  const { t } = useTranslation();
  const { user, hasPermission } = useUserContext();
  const navigate = useNavigate();
  const { pushToast } = useToastContext();

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const getRoles = async (tenantId) => {
    try {
      setLoading(true);
      const response = await roleAndPermissionApi.getRoles(tenantId, false);
      setRoles(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.tenantId) {
      getRoles(user.tenantId);
    }
  }, [user?.tenantId]);

  const handleOpenDeleteDialog = (row) => {
    setSelectedRole(row);
    setDeleteDialogOpen(true);
  };

  const handleDeleteRole = async () => {
    try {
      setLoading(true);
      await roleAndPermissionApi.deleteRole(user.tenantId, selectedRole.id);
      pushToast({
        message: t('pages.roles.toasts.deleted'),
        severity: 'success',
      });
      getRoles(user.tenantId);
      setSelectedRole();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleNewRole = () => {
    navigate(`${path.NEW_ROLE}`);
  };

  const handleEditRole = (row) => {
    navigate(`${path.ROLES}/${row.id}`);
  };

  const actionColumn = (props) => {
    return (
      <Box display="flex">
        {hasPermission('system:configure:roles:update') && (
          <Tooltip title={t('pages.roles.actions.tooltips.edit')} arrow>
            <IconButton
              size="small"
              onClick={() => handleEditRole(props.row)}
              disableRipple
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
        )}
        {hasPermission('system:configure:roles:delete') && (
          <Tooltip title={t('pages.roles.actions.tooltips.delete')} arrow>
            <IconButton
              size="small"
              onClick={() => handleOpenDeleteDialog(props.row)}
            >
              <Delete color={'error'} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: t('pages.roles.columns.name'),
      headerAlign: 'left',
      align: 'left',
      hideable: false,
      width: 200,
    },
    {
      field: 'description',
      headerName: t('pages.roles.columns.description'),
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      hideable: false,
      width: 150,
    },
    {
      field: 'createdBy',
      headerName: t('pages.roles.columns.createdBy'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdDate',
      headerName: t('pages.roles.columns.createdDate'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (props) => {
        return (
          <Typography>
            {format(parseISO(props.row.createdDate), 'do MMM yyyy h:m:s a')}
          </Typography>
        );
      },
    },
    {
      field: 'lastUpdatedBy',
      headerName: t('pages.roles.columns.lastUpdatedBy'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'lastUpdatedDate',
      headerName: t('pages.roles.columns.lastUpdatedDate'),
      headerAlign: 'left',
      width: 150,
      align: 'left',
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.lastUpdatedDate
              ? format(
                  parseISO(props.row.lastUpdatedDate),
                  'do MMM yyyy h:m:s a',
                )
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('pages.roles.columns.actions'),
      width: 300,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  return (
    <Box>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '80vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container style={{ maxWidth: '95vw' }}>
          <DataGridTable
            rows={roles}
            columns={columns}
            header={t('pages.roles.header')}
            allowNewRecord={hasPermission('system:configure:roles:create')}
            onRowDoubleClick={handleEditRole}
            onNewRecord={handleNewRole}
            error={error}
          />
        </Grid>
      )}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title={t('pages.roles.actions.delete.title')}
        messageLine1={t('pages.roles.actions.delete.messageLine1', {
          itemName: selectedRole?.name,
        })}
        messageLine2={t('pages.roles.actions.delete.messageLine2')}
        onYesClick={handleDeleteRole}
        onNoClick={handleCloseDeleteDialog}
      />
    </Box>
  );
};

RolesPage.propTypes = {
  row: PropTypes.object,
};

export default RolesPage;
