/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { coreApiHttpClient } from './httpClients.';

const fileCollectionApi = {
  getFileCollections: async (tenantId, includeChildRecords) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/fileCollections?includeChildRecords=${includeChildRecords}`,
    );
    const { data } = response;
    return data;
  },

  getFileCollection: async (
    tenantId,
    fileCollectionId,
    includeChildRecords,
  ) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}?includeChildRecords=${includeChildRecords}`,
    );
    const { data } = response;
    return data;
  },

  saveFileCollection: async (tenantId, fileCollection) => {
    let response;
    if (fileCollection.id) {
      response = await coreApiHttpClient.put(
        `/api/tenants/${tenantId}/fileCollections/${fileCollection.id}`,
        fileCollection,
      );
    } else {
      response = await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/fileCollections`,
        fileCollection,
      );
    }
    const { data } = response;
    return data;
  },

  deleteFileCollection: async (tenantId, fileCollectionId) => {
    await coreApiHttpClient.delete(
      `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}`,
    );
  },
};

export default fileCollectionApi;
