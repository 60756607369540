/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import * as path from '#constants/uiPaths.constant';
import QnaPage from '#pages/qna';
import RolesPage from '#pages/roles';
import ConfigurePage from '#pages/configure';
import FileCollectionsPage from '#pages/fileCollections/fileCollections.page';
import TenantPage from '#pages/tenant';
import RolePage from '#pages/role';
import FileCollectionPage from '#pages/fileCollection/fileCollection.page';
import DataSource from '#components/dataSource';
import AuditRecordsPage from '#pages/auditRecords/auditRecords.page';
import ProtectedRoute from './protectedRoute';
import UnauthorizedPage from '#pages/unauthorized/unauthorized.page';
import ErrorFallbackPage from '#pages/errorFallbackPage';
import FileProcessingStatePage from '#pages/fileProcessingState/fileProcessingState.page';

const AppRoutes = ({ appHeaderHeight }) => {
  return (
    <Routes>
      <Route
        path={path.QNA}
        element={
          <ProtectedRoute requiredPermissions={['qna']}>
            <QnaPage appHeaderHeight={appHeaderHeight} />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.CONFIGURE}
        element={
          <ProtectedRoute
            requiredPermissions={[
              'qna:configure',
              'coa:configure',
              'system:configure',
            ]}
          >
            <ConfigurePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.TENANT}
        element={
          <ProtectedRoute requiredPermissions={['system:tenant']}>
            <TenantPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.FILE_COLLECTIONS}
        element={
          <ProtectedRoute
            requiredPermissions={['qna:configure:fileCollection']}
          >
            <FileCollectionsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.NEW_FILE_COLLECTION}
        element={
          <ProtectedRoute
            requiredPermissions={['qna:configure:fileCollection:create']}
          >
            <FileCollectionPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.EDIT_FILE_COLLECTION}
        element={
          <ProtectedRoute
            requiredPermissions={['qna:configure:fileCollection']}
          >
            <FileCollectionPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.NEW_DATA_SOURCE}
        element={
          <ProtectedRoute
            requiredPermissions={[
              'qna:configure:fileCollection:dataSource:create',
            ]}
          >
            <DataSource />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.EDIT_DATA_SOURCE}
        element={
          <ProtectedRoute
            requiredPermissions={['qna:configure:fileCollection:dataSource']}
          >
            <DataSource />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.ROLES}
        element={
          <ProtectedRoute requiredPermissions={['system:configure:roles']}>
            <RolesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.NEW_ROLE}
        element={
          <ProtectedRoute
            requiredPermissions={['system:configure:roles:create']}
          >
            <RolePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.EDIT_ROLE}
        element={
          <ProtectedRoute requiredPermissions={['system:configure:roles']}>
            <RolePage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.AUDIT_RECORDS}
        element={
          <ProtectedRoute requiredPermissions={['system:audits']}>
            <AuditRecordsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={path.FILE_PROCESSING_STATES}
        element={
          <ProtectedRoute requiredPermissions={['system:audits']}>
            <FileProcessingStatePage />
          </ProtectedRoute>
        }
      />
      <Route path={path.UNAUTHORIZED} element={<UnauthorizedPage />} />
      <Route path={path.ERROR} element={<ErrorFallbackPage />} />
    </Routes>
  );
};

AppRoutes.propTypes = {
  appHeaderHeight: PropTypes.number,
};
export default AppRoutes;
