/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { PrimaryLine, SecondaryLine } from './conversationList.styled';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import ConfirmationDialog from '#components/confirmationDialog';
import { useUserContext } from '#contexts/userContext';

const ConversationList = ({
  conversations = [],
  conversation,
  onChange,
  onDeleteConversation,
}) => {
  const { hasPermission } = useUserContext();
  const { t } = useTranslation();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const openDeleteConfirmation = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConversation = () => {
    if (conversation) {
      onDeleteConversation(conversation);
    }
    setDeleteDialogOpen(false);
  };

  const renderConversation = (c, selected) => {
    return (
      <ListItemButton
        key={c.id}
        alignItems="flex-start"
        dense
        selected={selected}
        onClick={() => onChange(c)}
      >
        <ListItemText
          primary={<PrimaryLine>{c.name}</PrimaryLine>}
          color="primary"
          secondary={
            <SecondaryLine>
              {format(parseISO(c.startDate), 'do MMM yyyy h:mm:ss a')}
            </SecondaryLine>
          }
          disableTypography
        />
        {hasPermission('qna:conversation:delete') && selected && (
          <IconButton
            size="small"
            onClick={() => openDeleteConfirmation()}
            color="error"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </ListItemButton>
    );
  };

  return (
    <Box sx={{ overflow: 'auto' }}>
      <List sx={{ width: '100%' }} dense>
        {conversations.length === 0 && (
          <ListItemButton dense>
            <ListItemText
              primary={t('components.conversationList.noConversation')}
            />
          </ListItemButton>
        )}
        {conversation && renderConversation(conversation, true)}
        {conversations
          .filter((c) => c.id !== conversation?.id)
          .map((c) => renderConversation(c))}
      </List>
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title={t('components.conversationList.actions.delete.title')}
        messageLine1={t(
          'components.conversationList.actions.delete.messageLine1',
          {
            itemName: conversation?.name,
          },
        )}
        messageLine2={t(
          'components.conversationList.actions.delete.messageLine2',
        )}
        onYesClick={handleDeleteConversation}
        onNoClick={handleCloseDeleteDialog}
      />
    </Box>
  );
};

ConversationList.propTypes = {
  conversations: PropTypes.array,
  conversation: PropTypes.object,
  onChange: PropTypes.func,
  onDeleteConversation: PropTypes.func,
};

export default ConversationList;
