const DefaultValues = {
  maxMessagesPerPoll: 200,
  textExtractionStrategy: 'SIMPLE_EXTRACTION',
  normalizeNewLines: true,
  normalizeWhiteSpaces: true,
  removeHeaderFooter: false,
  headerFooterMinChars: 3,
  headerFooterMaxChars: 30,
  textChunkingStrategy: 'PASSAGE',
  minChunkWords: 30,
  maxChunkWords: 300,
  respectSentenceBoundary: true,
  straightenSentences: true,
  embeddingStrategy: 'NONE',
  openAiEmbeddingModel: '',
  azureOpenAiEmbeddingDeployment: '',
  azureOpenAiEmbeddingModel: '',
  errorHandlingStrategy: 'LOG_MARK_FORGET',
  maxNoOfTries: 3,
  transformerEmbeddingModel: '',
};

export default DefaultValues;
