/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, CircularProgress } from '@mui/material';
import ingestionApis from '#services/apis/ingestion.apis';
import PdfViewer from '#components/pdfViewer';
import { useToastContext } from '#contexts/providers/toast.provider';
import { useTranslation } from 'react-i18next';

const ReferenceDocumentDialog = ({ referenceDocument, onClose, open }) => {
  const [docUrl, setDocUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { pushToast } = useToastContext();
  const { t } = useTranslation();

  useEffect(() => {
    const loadFile = async () => {
      try {
        setLoading(true);
        const { tenantId, fileCollectionId, dataSourceId, fileName } =
          referenceDocument;
        const file = await ingestionApis.getFile(
          tenantId,
          fileCollectionId,
          dataSourceId,
          fileName,
        );
        const base64String = btoa(
          new Uint8Array(file).reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, ''),
        );
        setDocUrl(`data:application/pdf;base64,${base64String}`);
      } catch (error) {
        setError(error.message);
        pushToast({
          message: t('components.referenceDocumentDialog.toasts.error'),
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };
    if (referenceDocument) {
      loadFile();
    }
  }, [referenceDocument]);

  const handleDownload = () => {
    window.open(ingestionApis.getFileUrl(), '_blank');
  };

  if (loading) {
    return (
      <Dialog onClose={onClose} open={open} maxWidth={'lg'}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    !error && (
      <Dialog onClose={onClose} open={open} maxWidth={'lg'}>
        <DialogContent sx={{ padding: 0 }}>
          {docUrl && (
            <PdfViewer
              referenceDocument={referenceDocument}
              url={docUrl}
              onClose={onClose}
              onDownload={handleDownload}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  );
};

ReferenceDocumentDialog.propTypes = {
  referenceDocument: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
export default ReferenceDocumentDialog;
