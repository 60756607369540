/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const UnauthorizedPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography>{t('pages.unauthorized.message')}</Typography>
    </Box>
  );
};

export default UnauthorizedPage;
