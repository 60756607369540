import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@mui/material';

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Blinker = styled('span')(({ color }) => ({
  animation: `${blinkAnimation} 1s infinite`,
  display: 'inline-block',
  color: color,
}));

const BlinkingText = ({ color = 'inderit', children }) => {
  return <Blinker color={color}>{children}</Blinker>;
};

BlinkingText.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
};
export default BlinkingText;
