/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  CircularProgress,
  IconButton,
  Box,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import DataGridTable from '#components/datagridTable';
import { useUserContext } from '#contexts/userContext';
import { fileCollectionApi } from '#services/apis';
import * as path from '#constants/uiPaths.constant';
import ConfirmationDialog from '#components/confirmationDialog';
import { format, parseISO } from 'date-fns';
import { useToastContext } from '#contexts/providers/toast.provider';

const FileCollectionsPage = () => {
  const { t } = useTranslation();
  const { user, hasPermission } = useUserContext();
  const navigate = useNavigate();
  const { pushToast } = useToastContext();

  const [fileCollections, setFileCollections] = useState([]);
  const [selectedFileCollection, setSelectedFileCollection] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const getFileCollections = async (tenantId) => {
    try {
      setLoading(true);
      const response = await fileCollectionApi.getFileCollections(
        tenantId,
        false,
      );
      setFileCollections(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.tenantId) {
      getFileCollections(user?.tenantId);
    }
  }, [user?.tenantId]);

  const handleOpenDeleteDialog = (row) => {
    setDeleteDialogOpen(true);
    setSelectedFileCollection(row);
  };

  const handleDeleteFileCollection = async () => {
    try {
      setLoading(true);
      await fileCollectionApi.deleteFileCollection(
        selectedFileCollection.tenantId,
        selectedFileCollection.id,
      );
      pushToast({
        message: t('pages.fileCollections.toasts.deleted'),
        severity: 'success',
      });
      getFileCollections(user.tenantId);
      setSelectedFileCollection();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleNewFileCollection = () => {
    navigate(`${path.NEW_FILE_COLLECTION}`);
  };

  const handleEditFileCollection = (row) => {
    navigate(`${path.FILE_COLLECTIONS}/${row.id}`);
  };

  const actionColumn = (props) => {
    return (
      <Box display="flex">
        {hasPermission('qna:configure:fileCollection') && (
          <Tooltip title="Edit" arrow>
            <IconButton
              size="small"
              onClick={() => handleEditFileCollection(props.row)}
              disableRipple
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        {hasPermission('qna:configure:fileCollection:delete') && (
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => handleOpenDeleteDialog(props.row)}
              color="error"
              disableRipple
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: t('pages.fileCollections.columns.name'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'description',
      headerName: t('pages.fileCollections.columns.description'),
      minWidth: 200,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdBy',
      headerName: t('pages.fileCollections.columns.createdBy'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdDate',
      headerName: t('pages.fileCollections.columns.createdDate'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (props) => {
        return (
          <Typography>
            {format(parseISO(props.row.createdDate), 'do MMM yyyy h:m:s a')}
          </Typography>
        );
      },
    },
    {
      field: 'lastUpdatedBy',
      headerName: t('pages.fileCollections.columns.lastUpdatedBy'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'lastUpdatedDate',
      headerName: t('pages.fileCollections.columns.lastUpdatedDate'),
      headerAlign: 'left',
      width: 150,
      align: 'left',
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.lastUpdatedDate
              ? format(
                  parseISO(props.row.lastUpdatedDate),
                  'do MMM yyyy h:m:s a',
                )
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'actions',
      headerName: t('pages.fileCollections.columns.actions'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  return (
    <Box>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '80vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container style={{ maxWidth: '95vw' }}>
          <DataGridTable
            rows={fileCollections}
            columns={columns}
            header={t('pages.fileCollections.header')}
            onRowDoubleClick={handleEditFileCollection}
            allowNewRecord={hasPermission(
              'qna:configure:fileCollection:create',
            )}
            onNewRecord={handleNewFileCollection}
            error={error}
          ></DataGridTable>
        </Grid>
      )}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title={t('pages.fileCollections.actions.delete.title')}
        messageLine1={t('pages.fileCollections.actions.delete.messageLine1', {
          itemName: selectedFileCollection?.name,
        })}
        messageLine2={t('pages.fileCollections.actions.delete.messageLine2')}
        onYesClick={handleDeleteFileCollection}
        onNoClick={handleCloseDeleteDialog}
      />
    </Box>
  );
};

FileCollectionsPage.propTypes = { row: PropTypes.object };

export default FileCollectionsPage;
