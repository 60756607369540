import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { useUserContext } from '#contexts/userContext';

const UserAvatar = ({ size = 35 }) => {
  const userContext = useUserContext();

  const [userInitials, setUserInitials] = useState(' ');
  const [userName, setuserName] = useState('');

  useEffect(() => {
    if (userContext.user) {
      const userName = userContext.user.name;
      if (userName) {
        setuserName(userName);
        const nameParts = userName.split(' ');
        nameParts.length > 1
          ? setUserInitials(`${nameParts[0][0]}${nameParts[1][0]}`)
          : setUserInitials(nameParts[0][0]);
      }
    }
  }, [userContext.user]);

  const stringToColor = (string) => {
    let hash = 0;

    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  return (
    <Avatar
      sx={{
        bgcolor: stringToColor(userName),
        padding: 1,
        width: size,
        height: size,
        fontSize: '14px',
      }}
    >
      {userInitials}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  size: PropTypes.number,
};

export default UserAvatar;
