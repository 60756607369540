/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Paper, InputBase, IconButton, styled } from '@mui/material';
import { Search } from '@mui/icons-material';

export const StyledPaper = styled(Paper)(({ disabled, theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1),
  borderRadius: theme.spacing(0),
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 50,
  zIndex: 1,
  boxShadow: 'none',
  border: `1px solid ${theme.palette.text.caption}`,
  backgroundColor: disabled
    ? theme.palette.action.disabledBackground
    : '-moz-initial',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: theme.spacing(1),
  fontSize: '16px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  },
}));

export const StyledSearchIcon = styled(Search)(({ value, theme }) => ({
  color: theme.palette.common.white,
}));

export const StyledSearchButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.spacing(0),
  borderTopRightRadius: theme.spacing(0),
  borderBottomRightRadius: theme.spacing(0),
  padding: theme.spacing(1, 3),
  height: 50,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:disabled': {
    backgroundColor: theme.palette.primary.main,
  },
}));
