/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import SelectField from '#components/selectField';
import {
  Grid,
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import BooleanField from '#components/booleanField';
import NumberField from '#components/numberField';
import TextField from '#components/textField';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import defaults from './defaultValues';
import { useToastContext } from '#contexts/providers/toast.provider';
import { parametersApi } from '#services/apis';

const TEXT_CHUNKING_OPTIONS = ['PASSAGE', 'SENTENCE', 'WORD', 'NONE'];
const TEXT_EXTRACTION_OPTIONS = ['SIMPLE_EXTRACTION'];
const TEXT_EMBEDDING_OPTIONS = [
  'NONE',
  'OPENAI',
  'AZURE_OPENAI',
  'TRANSFORMER',
];
const TEXT_ERROR_HANDLING_OPTIONS = ['LOG_MARK_FORGET', 'LOG_MARK_RETRY_NEXT'];

const ProcessingParameters = ({
  tenantId,
  fileCollectionId,
  dataSourceId,
  editable = true,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [open, setOpen] = useState(true);
  const [processingParameters, setProcessingParameters] = useState(
    defaultValues || defaults,
  );
  const [prevValues, setPrevValues] = useState(defaultValues || defaults);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [hasChanged, setHasChanged] = useState(defaultValues === undefined);
  const [loading, setLoading] = useState(false);

  const handleChange = (fieldName, value) => {
    setProcessingParameters({ ...processingParameters, [fieldName]: value });
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    setErrors({});
    setProcessingParameters({ ...prevValues });
    setHasChanged(false);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasError = false;
    setError();
    if (!processingParameters['maxMessagesPerPoll']) {
      errors['maxMessagesPerPoll'] = t(
        'components.processingParameters.errors.valueBetween',
        { range: '1-1000' },
      );
      hasError = true;
    }
    if (!processingParameters['textExtractionStrategy']) {
      errors['textExtractionStrategy'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (processingParameters['headerFooterMinChars'] < 0) {
      errors['headerFooterMinChars'] = t(
        'components.processingParameters.errors.positiveNumber',
      );
      hasError = true;
    }
    if (processingParameters['headerFooterMaxChars'] < 0) {
      errors['headerFooterMaxChars'] = 'Please select a positive number';
      hasError = true;
    }
    if (!processingParameters['textChunkingStrategy']) {
      errors['textChunkingStrategy'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (processingParameters['minChunkWords'] < 0) {
      errors['minChunkWords'] = t(
        'components.processingParameters.errors.positiveNumber',
      );
      hasError = true;
    }
    if (
      processingParameters['minChunkWords'] < 0 &&
      processingParameters['minChunkWords'] >=
        processingParameters['minChunkWords']
    ) {
      errors['maxChunkWords'] = t(
        'components.processingParameters.errors.maxChunkWords',
      );
      hasError = true;
    }
    if (!processingParameters['embeddingStrategy']) {
      errors['embeddingStrategy'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      processingParameters['embeddingStrategy'] === 'OPENAI' &&
      !processingParameters['openAiEmbeddingModel']
    ) {
      errors['openAiEmbeddingModel'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      processingParameters['embeddingStrategy'] === 'AZURE_OPENAI' &&
      !processingParameters['azureOpenAiEmbeddingDeployment']
    ) {
      errors['azureOpenAiEmbeddingDeployment'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      processingParameters['embeddingStrategy'] === 'AZURE_OPENAI' &&
      !processingParameters['azureOpenAiEmbeddingModel']
    ) {
      errors['azureOpenAiEmbeddingModel'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      processingParameters['embeddingStrategy'] === 'TRANSFORMER' &&
      !processingParameters['transformerEmbeddingModel']
    ) {
      errors['transformerEmbeddingModel'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (!processingParameters['errorHandlingStrategy']) {
      errors['errorHandlingStrategy'] = t(
        'components.processingParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (!hasError) {
      setLoading(true);
      setErrors({});
      try {
        await parametersApi.saveProcessingParameters(
          tenantId,
          fileCollectionId,
          dataSourceId,
          processingParameters,
        );
        pushToast({
          message: t('components.processingParameters.toasts.saved'),
          severity: 'success',
        });
        setPrevValues({ ...processingParameters });
        setHasChanged(false);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t('components.processingParameters.header')}
        subheader={error && <Alert severity="error">{error.message}</Alert>}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.processingParameters.fields.maxMessagesPerPoll',
                )}
                value={processingParameters.maxMessagesPerPoll}
                required
                min={1}
                step={1}
                max={1000}
                onChange={(value) => handleChange('maxMessagesPerPoll', value)}
                disabled={!editable}
                error={errors['maxMessagesPerPoll']}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.processingParameters.fields.sections.textExtraction',
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.processingParameters.fields.textExtractionStrategy',
                )}
                value={processingParameters.textExtractionStrategy}
                options={TEXT_EXTRACTION_OPTIONS}
                required
                onChange={(value) =>
                  handleChange('textExtractionStrategy', value)
                }
                disabled={!editable}
                error={errors['textExtractionStrategy']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.processingParameters.fields.normalizeNewLines',
                )}
                value={processingParameters.normalizeNewLines}
                onChange={(value) => handleChange('normalizeNewLines', value)}
                disabled={!editable}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.processingParameters.fields.normalizeWhiteSpaces',
                )}
                value={processingParameters.normalizeWhiteSpaces}
                onChange={(value) =>
                  handleChange('normalizeWhiteSpaces', value)
                }
                disabled={!editable}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.processingParameters.fields.removeHeaderFooter',
                )}
                value={processingParameters.removeHeaderFooter}
                onChange={(value) => handleChange('removeHeaderFooter', value)}
                disabled={!editable}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.processingParameters.fields.headerFooterMinChars',
                )}
                value={processingParameters.headerFooterMinChars}
                min={0}
                step={1}
                onChange={(value) =>
                  handleChange('headerFooterMinChars', value)
                }
                disabled={!editable}
                error={errors['headerFooterMinChars']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.processingParameters.fields.headerFooterMaxChars',
                )}
                value={processingParameters.headerFooterMaxChars}
                min={processingParameters.headerFooterMinChars || 0}
                step={1}
                onChange={(value) =>
                  handleChange('headerFooterMaxChars', value)
                }
                disabled={!editable}
                error={errors['headerFooterMaxChars']}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.processingParameters.fields.sections.textChunking',
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.processingParameters.fields.textChunkingStrategy',
                )}
                value={processingParameters.textChunkingStrategy}
                options={TEXT_CHUNKING_OPTIONS}
                required
                onChange={(value) =>
                  handleChange('textChunkingStrategy', value)
                }
                disabled={!editable}
                error={errors['textChunkingStrategy']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.processingParameters.fields.minChunkWords',
                )}
                value={processingParameters.minChunkWords}
                min={0}
                step={1}
                onChange={(value) => handleChange('minChunkWords', value)}
                disabled={!editable}
                error={errors['minChunkWords']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.processingParameters.fields.maxChunkWords',
                )}
                value={processingParameters.maxChunkWords}
                min={processingParameters.minChunkWords | 0}
                step={1}
                onChange={(value) => handleChange('maxChunkWords', value)}
                disabled={!editable}
                error={errors['maxChunkWords']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.processingParameters.fields.respectSentenceBoundary',
                )}
                value={processingParameters.respectSentenceBoundary}
                onChange={(value) =>
                  handleChange('respectSentenceBoundary', value)
                }
                disabled={!editable}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.processingParameters.fields.straightenSentences',
                )}
                value={processingParameters.straightenSentences}
                onChange={(value) => handleChange('straightenSentences', value)}
                disabled={!editable}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.processingParameters.fields.sections.textEmbeddings',
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.processingParameters.fields.embeddingStrategy',
                )}
                value={processingParameters.embeddingStrategy}
                options={TEXT_EMBEDDING_OPTIONS}
                required
                onChange={(value) => handleChange('embeddingStrategy', value)}
                disabled={!editable}
                error={errors['embeddingStrategy']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.processingParameters.fields.openAiEmbeddingModel',
                )}
                value={processingParameters.openAiEmbeddingModel}
                onChange={(value) =>
                  handleChange('openAiEmbeddingModel', value)
                }
                disabled={!editable}
                error={errors['openAiEmbeddingModel']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.processingParameters.fields.azureOpenAiEmbeddingDeployment',
                )}
                value={processingParameters.azureOpenAiEmbeddingDeployment}
                onChange={(value) =>
                  handleChange('azureOpenAiEmbeddingDeployment', value)
                }
                disabled={!editable}
                error={errors['azureOpenAiEmbeddingDeployment']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.processingParameters.fields.azureOpenAiEmbeddingModel',
                )}
                value={processingParameters.azureOpenAiEmbeddingModel}
                onChange={(value) =>
                  handleChange('azureOpenAiEmbeddingModel', value)
                }
                disabled={!editable}
                error={errors['azureOpenAiEmbeddingModel']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.processingParameters.fields.transformerEmbeddingModel',
                )}
                value={processingParameters.transformerEmbeddingModel}
                onChange={(value) =>
                  handleChange('transformerEmbeddingModel', value)
                }
                disabled={!editable}
                error={errors['transformerEmbeddingModel']}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.processingParameters.fields.sections.errorHandling',
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.processingParameters.fields.errorHandlingStrategy',
                )}
                value={processingParameters.errorHandlingStrategy}
                options={TEXT_ERROR_HANDLING_OPTIONS}
                required
                onChange={(value) =>
                  handleChange('errorHandlingStrategy', value)
                }
                disabled={!editable}
                error={errors['errorHandlingStrategy']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t('components.processingParameters.fields.maxNoOfTries')}
                value={processingParameters.maxNoOfTries}
                min={0}
                step={1}
                max={5}
                onChange={(value) => handleChange('maxNoOfTries', value)}
                disabled={!editable}
                error={errors['maxNoOfTries']}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {loading ? (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <CircularProgress />
          </CardActions>
        ) : (
          hasChanged && (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleCancelClick}>
                {t('components.processingParameters.actions.cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSaveClick}
              >
                {t('components.processingParameters.actions.save')}
              </Button>
            </CardActions>
          )
        )}
      </Collapse>
    </Card>
  );
};

ProcessingParameters.propTypes = {
  tenantId: PropTypes.string,
  fileCollectionId: PropTypes.string,
  dataSourceId: PropTypes.string,
  editable: PropTypes.bool,
  defaultValues: PropTypes.object,
  onChange: PropTypes.func,
};

export default ProcessingParameters;
