/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { coreApiHttpClient } from './httpClients.';

const parametersApi = {
  saveProcessingParameters: async (
    tenantId,
    fileCollectionId,
    dataSourceId,
    processingParameters,
  ) => {
    if (dataSourceId) {
      await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/dataSources/${dataSourceId}/processingParameters`,
        processingParameters,
      );
    } else if (fileCollectionId) {
      await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/processingParameters`,
        processingParameters,
      );
    } else {
      await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/processingParameters`,
        processingParameters,
      );
    }
  },

  saveSearchParameters: async (
    tenantId,
    fileCollectionId,
    searchParameters,
  ) => {
    if (fileCollectionId) {
      await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/searchParameters`,
        searchParameters,
      );
    } else {
      await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/searchParameters`,
        searchParameters,
      );
    }
  },

  saveLlmParameters: async (tenantId, llmParameters) => {
    await coreApiHttpClient.post(
      `/api/tenants/${tenantId}/processingParameters`,
      llmParameters,
    );
  },

  saveConversationMessageParameters: async (
    tenantId,
    conversationMessageParameters,
  ) => {
    await coreApiHttpClient.post(
      `/api/tenants/${tenantId}/conversationMessageParameters`,
      conversationMessageParameters,
    );
  },

  saveChatEncryptionParameters: async (tenantId, chatEncryptionParameters) => {
    await coreApiHttpClient.post(
      `/api/tenants/${tenantId}/chatEncryptionParameters`,
      chatEncryptionParameters,
    );
  },
};

export default parametersApi;
