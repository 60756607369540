import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  StyledPaper,
  StyledInputBase,
  StyledSearchIcon,
  StyledSearchButton,
} from './chatInput.styled';

const ChatInputBox = ({ disabled, onSubmit }) => {
  const [value, setValue] = useState('');

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value) {
      onSubmit(value);
    }
    setValue('');
  };

  const onKeyPressed = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <StyledPaper elevation={3} disabled={disabled}>
      <StyledInputBase
        value={value}
        fullWidth
        onChange={onChange}
        onKeyDown={onKeyPressed}
        disabled={disabled}
        endAdornment={
          <StyledSearchButton
            disabled={disabled || !value}
            aria-label="search"
            onClick={handleSubmit}
            edge="end"
            color="primary"
          >
            <StyledSearchIcon />
          </StyledSearchButton>
        }
      />
    </StyledPaper>
  );
};

ChatInputBox.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ChatInputBox;
