/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

const muiTableContainer = {
  styleOverrides: {
    root: ({ theme }) => ({
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
    }),
  },
};

const muiTable = {
  styleOverrides: {
    width: '100%',
    borderCollapse: 'collapse',
  },
};

const muiTableCell = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: theme.spacing(1.5),
      textAlign: 'left',
    }),
  },
};

const muiTableHead = {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.grey[100],
      fontWeight: theme.typography.h2.fontWeight,
    }),
  },
};

const muiTableRow = {
  styleOverrides: {
    root: ({ theme }) => ({
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.grey[50],
      },
    }),
  },
};

export { muiTableContainer, muiTable, muiTableCell, muiTableHead, muiTableRow };
