/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Grid, CircularProgress, Box, Button, Typography } from '@mui/material';
import DataGridTable from '#components/datagridTable';
import { useUserContext } from '#contexts/userContext';
import { auditApi } from '#services/apis';
import { format, parseISO } from 'date-fns';
import { Record, Filters } from '#components/auditRecords';
import * as path from '#constants/uiPaths.constant';

const AuditRecordsPage = () => {
  const { t } = useTranslation();
  const { user, hasPermission } = useUserContext();

  const [auditRecords, setAuditRecords] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isRecordOpen, setRecordOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [defaultFilter, setDefaultFilter] = useState({
    filters: {
      actions: [],
      recordTypes: [],
      userIds: [],
      startDate: null,
      endDate: null,
      maxRecords: 1000,
    },
  });

  const getAuditRecords = async (tenantId, filters = defaultFilter) => {
    try {
      setLoading(true);
      const response = await auditApi.getAuditRecords(tenantId, filters);
      setAuditRecords(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.tenantId) {
      getAuditRecords(user?.tenantId);
    }
  }, [user?.tenantId]);

  const handleOpenRecord = (row) => {
    setRecordOpen(true);
    setSelectedRecord(row);
  };

  const handleCloseRecord = () => {
    setRecordOpen(false);
  };

  const columns = [
    {
      field: 'performedBy',
      headerName: t('pages.auditRecords.columns.performedBy'),
      width: 350,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'recordType',
      headerName: t('pages.auditRecords.columns.recordType'),
      minWidth: 300,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'action',
      headerName: t('pages.auditRecords.columns.action'),
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (props) => {
        let textColor = 'black';
        switch (props.row.action) {
          case 'create':
            textColor = 'green';
            break;
          case 'update':
            textColor = 'RoyalBlue';
            break;
          case 'delete':
            textColor = 'red';
            break;
          default:
            textColor = 'black';
        }

        return (
          <div style={{ color: textColor }}>
            {props.row.action.toUpperCase()}
          </div>
        );
      },
    },
    {
      field: 'performedTime',
      headerName: t('pages.auditRecords.columns.performedTime'),
      width: 250,
      headerAlign: 'right',
      align: 'right',
      renderCell: (props) => {
        return (
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {!isHovering && (
              <div>
                <Typography variant="body1">
                  {format(parseISO(props.row.performedTime), 'do MMM yyyy')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {format(parseISO(props.row.performedTime), 'HH:mm:ss')}
                </Typography>
              </div>
            )}
            {isHovering && (
              <Button
                variant="text"
                size="small"
                onClick={() => handleOpenRecord(props.row)}
              >
                {t('pages.auditRecords.actions.viewDetails')}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const onFilterChange = (filters) => {
    getAuditRecords(user?.tenantId, { filters: filters });
    setDefaultFilter(filters);
  };

  if (!hasPermission('system:audits')) {
    return <Navigate to={path.UNAUTHORIZED} />;
  }

  return (
    <Box>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '80vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ maxWidth: '95vw' }}
        >
          <Grid item xs={12} sm={12} md={9}>
            <DataGridTable
              rows={auditRecords}
              columns={columns}
              rowHeight={80}
              error={error}
              header={t('pages.auditRecords.header')}
            ></DataGridTable>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Filters
              onFilterChange={onFilterChange}
              defaultFilters={defaultFilter}
            />
          </Grid>
        </Grid>
      )}
      <Record
        open={isRecordOpen}
        title={`${selectedRecord?.performedBy} ${selectedRecord?.action}d ${selectedRecord?.recordType}`}
        oldRecord={
          selectedRecord?.oldRecord
            ? JSON.parse(selectedRecord?.oldRecord)
            : null
        }
        newRecord={
          selectedRecord?.newRecord
            ? JSON.parse(selectedRecord?.newRecord)
            : null
        }
        onCloseClick={handleCloseRecord}
      />
    </Box>
  );
};

AuditRecordsPage.propTypes = { row: PropTypes.object };

export default AuditRecordsPage;
