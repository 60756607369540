/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { ingestionApiHttpClient } from './httpClients.';

const ingestionApis = {
  getFileUrl: (tenantId, fileCollectionId, dataSourceId, fileName) => {
    return `/api/file/${tenantId}/${fileCollectionId}/${dataSourceId}?filename=${fileName}`;
  },
  getFile: async (tenantId, fileCollectionId, dataSourceId, fileName) => {
    const response = await ingestionApiHttpClient.get(
      `/api/file/${tenantId}/${fileCollectionId}/${dataSourceId}?filename=${fileName}`,
      { responseType: 'arraybuffer' },
    );
    const { data } = response;
    return data;
  },
};

export default ingestionApis;
