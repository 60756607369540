/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { coreApiHttpClient } from './httpClients.';

const cacheApi = {
  clearCache: async (tenantId, recordTypes) => {
    await coreApiHttpClient.post(`/api/tenants/${tenantId}/cache`, {
      recordTypes: recordTypes,
    });
  },
};

export default cacheApi;
