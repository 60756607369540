/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

const muiSelect = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(0),
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      width: 200,
      height: 30,
    }),
    icon: ({ theme }) => ({
      color: theme.palette.primary.main,
    }),
  },
};

export default muiSelect;
