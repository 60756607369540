/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '#contexts/userContext';
import * as path from '#constants/uiPaths.constant';
import ClearCacheDialog from './clearCacheDialog.component';
import { useState } from 'react';

const ConfigureOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hasPermission } = useUserContext();
  const [openDialog, setOpenDialog] = useState(false);

  const handleButtonClick = (path) => {
    navigate(path);
  };

  const openClearCacheDialog = () => {
    setOpenDialog(true);
  };

  const closeClearCacheDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Stack
      spacing={2}
      justifyContent="stretch"
      alignItems="stretch"
      alignContent={'stretch'}
      sx={{ height: '100%' }}
    >
      {hasPermission('system:tenant') && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleButtonClick(path.TENANT)}
        >
          {t('components.configureOptions.tenantConfiguration')}
        </Button>
      )}
      {hasPermission('qna:configure:fileCollection') && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleButtonClick(path.FILE_COLLECTIONS)}
        >
          {t('components.configureOptions.fileCollectionsDataSources')}
        </Button>
      )}
      {hasPermission('system:configure:roles') && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleButtonClick(path.ROLES)}
        >
          {t('components.configureOptions.rolesPermissions')}
        </Button>
      )}
      {hasPermission('system:audits') && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleButtonClick(path.AUDIT_RECORDS)}
        >
          {t('components.configureOptions.auditRecords')}
        </Button>
      )}
      {hasPermission('qna:configure:fileCollection') && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleButtonClick(path.FILE_PROCESSING_STATES)}
        >
          {t('components.configureOptions.fileProcessingStatus')}
        </Button>
      )}
      {hasPermission('system:cache') && (
        <Button
          color="primary"
          variant="contained"
          onClick={openClearCacheDialog}
        >
          {t('components.configureOptions.clearCache')}
        </Button>
      )}

      <ClearCacheDialog
        open={openDialog}
        onClose={closeClearCacheDialog}
      ></ClearCacheDialog>
    </Stack>
  );
};

export default ConfigureOptions;
