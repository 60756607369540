import PropTypes from 'prop-types';
import { useUserContext } from '#contexts/userContext';
import { Navigate } from 'react-router-dom';
import * as path from '#constants/uiPaths.constant';

const ProtectedRoute = ({ children, requiredPermissions }) => {
  const { hasAnyPermission } = useUserContext();

  if (hasAnyPermission(requiredPermissions)) return children;

  return <Navigate to={path.UNAUTHORIZED} />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  requiredPermissions: PropTypes.array,
};
export default ProtectedRoute;
