/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NumberField from '#components/numberField';
import TextField from '#components/textField';

const EndpointParameters = ({
  values = {},
  parameters = [],
  editable = true,
  onChange,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1} rowGap={2}>
      {parameters.length > 0 && (
        <Grid item xs={12}>
          <Divider>{t(`components.endpointParameters.header`)}</Divider>
        </Grid>
      )}
      {parameters.map((param, index) => (
        <Grid item xs={3} key={index}>
          {param.type === 'text' || param.type === 'password' ? (
            <TextField
              label={t(
                `components.endpointParameters.fields.${param.name || ''}`,
              )}
              value={values[param.name]}
              onChange={(value) => onChange(param.name, value)}
              fullWidth
              disabled={!editable}
              required={param.required}
              error={errors[param.name]}
              type={param.type}
            />
          ) : param.type === 'number' ? (
            <NumberField
              label={t(
                `components.endpointParameters.fields.${param.name || ''}`,
              )}
              value={values[param.name]}
              onChange={(value) => onChange(param.name, value)}
              fullWidth
              disabled={!editable}
              required={param.required}
              error={errors[param.name]}
            />
          ) : null}
        </Grid>
      ))}
    </Grid>
  );
};

EndpointParameters.propTypes = {
  values: PropTypes.object,
  parameters: PropTypes.array,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  editable: PropTypes.bool,
};

export default EndpointParameters;
