/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Alert,
  CardHeader,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MultipleSelectField from '#components/muiltipleSelectField';
import { useState } from 'react';
import { cacheApi } from '#services/apis';
import { useUserContext } from '#contexts/userContext';
import { useToastContext } from '#contexts/providers/toast.provider';

const RECORD_TYPES = ['FILECOLLECTIONS', 'DATASOURCES', 'ROLES', 'PERMISSIONS'];

const ClearCacheDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { pushToast } = useToastContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [recordTypes, setRecordTypes] = useState([]);

  const handleChange = (value) => {
    setRecordTypes(value);
  };

  const handleClearCache = async () => {
    try {
      setLoading(true);
      await cacheApi.clearCache(user?.tenantId, recordTypes);
      setLoading(false);
      onClose();
      pushToast({
        message: t('components.clearCacheDialog.toasts'),
        severity: 'success',
      });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('components.clearCacheDialog.header')}</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <Card variant="outlined">
            {error && (
              <CardHeader
                subheader={error && <Alert severity="error">{error}</Alert>}
              />
            )}
            <CardContent>
              <Grid container direction={'column'} spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <MultipleSelectField
                    label={t('components.clearCacheDialog.fields.recordTypes')}
                    value={recordTypes}
                    onChange={(value) => handleChange(value)}
                    options={RECORD_TYPES}
                  />
                </Grid>
                <Grid item xs={6} md={3} alignSelf="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClearCache}
                  >
                    {t('components.clearCacheDialog.actions.clear')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t('components.clearCacheDialog.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ClearCacheDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ClearCacheDialog;
