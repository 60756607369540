/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import BooleanField from '#components/booleanField';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '#components/textField';
import { useToastContext } from '#contexts/providers/toast.provider';
import { parametersApi } from '#services/apis';

const LlmParameters = ({ tenantId, defaultValues = {}, editable = true }) => {
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [open, setOpen] = useState(true);
  const [llmParameters, setLlmParameters] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  const handleChange = (fieldName, value) => {
    setLlmParameters({ ...llmParameters, [fieldName]: value });
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    setErrors({});
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasError = false;
    setError();
    if (!hasError) {
      setErrors({});
      try {
        await parametersApi.saveLlmParameters(tenantId, llmParameters);
        pushToast({
          message: t('components.llmParameters.toasts.saved'),
          severity: 'success',
        });
      } catch (error) {
        setError(error);
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t('components.llmParameters.header')}
        subheader={error && <Alert severity="error">{error.message}</Alert>}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid xs={6} md={3}>
              <BooleanField
                label={t('components.llmParameters.fields.useDefaultOpenAiKey')}
                value={llmParameters.useDefaultOpenAiKey}
                onChange={(value) => handleChange('useDefaultOpenAiKey', value)}
                disabled={!editable}
              />
            </Grid>
            <Grid xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.llmParameters.fields.useDefaultAzureOpenAiKey',
                )}
                value={llmParameters.useDefaultAzureOpenAiKey}
                onChange={(value) =>
                  handleChange('useDefaultAzureOpenAiKey', value)
                }
                disabled={!editable}
              />
            </Grid>
            {!llmParameters?.useDefaultOpenAiKey && (
              <Grid xs={4}>
                <TextField
                  label={t('components.llmParameters.fields.openAiApiKey')}
                  value={llmParameters.openAiApiKey}
                  onChange={(value) => handleChange('openAiApiKey', value)}
                  disabled={!editable}
                  error={errors['openAiApiKey']}
                />
              </Grid>
            )}
            {!llmParameters?.useDefaultAzureOpenAiKey && (
              <Grid xs={4}>
                <TextField
                  label={t(
                    'components.llmParameters.fields.azureOpenAiApiEndpoint',
                  )}
                  value={llmParameters.azureOpenAiApiEndpoint}
                  onChange={(value) =>
                    handleChange('azureOpenAiApiEndpoint', value)
                  }
                  disabled={!editable}
                  error={errors['azureOpenAiApiEndpoint']}
                />
              </Grid>
            )}
            {!llmParameters?.useDefaultAzureOpenAiKey && (
              <Grid xs={4}>
                <TextField
                  label={t('components.llmParameters.fields.azureOpenAiApiKey')}
                  value={llmParameters.azureOpenAiApiKey}
                  onChange={(value) => handleChange('azureOpenAiApiKey', value)}
                  disabled={!editable}
                  error={errors['azureOpenAiApiKey']}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>{' '}
        <Divider />
        {hasChanged && (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button size="small" onClick={handleCancelClick}>
              {t('components.llmParameters.actions.cancel')}
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
            >
              {t('components.llmParameters.actions.save')}
            </Button>
          </CardActions>
        )}
      </Collapse>
    </Card>
  );
};

LlmParameters.propTypes = {
  tenantId: PropTypes.string,
  defaultValues: PropTypes.object,
  editable: PropTypes.bool,
};

export default LlmParameters;
