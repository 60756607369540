/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { User } from 'oidc-client-ts';
import { AUTHORITY, CLIENT_ID } from '#config/auth.config';

const SESSION_OIDC_TOKEN_KEY = 'oidc.user';

const getUserFromSession = () => {
  const sessionKey = `${SESSION_OIDC_TOKEN_KEY}:${AUTHORITY}:${CLIENT_ID}`;
  const oidcUser = sessionStorage.getItem(sessionKey);

  if (!oidcUser) {
    return null;
  }

  try {
    const user = User.fromStorageString(oidcUser);
    return user;
  } catch (error) {
    console.error('Error parsing user from storage string:', error);
    return null;
  }
};

const getUsersAccessToken = () => {
  const user = getUserFromSession();
  return user?.access_token;
};

export { getUserFromSession, getUsersAccessToken };
