import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { MenuOutlined, Done } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useSizing from '#hooks/useSizing.hook';
import { TermsAndConditions } from './qna.styled';
import ChatWindow from '#components/chatWindow';
import QnaContext from '#components/qnaContext/qnaContext.component';
import { useUserContext } from '#contexts/userContext';

const LeftPanel = ({
  appHeaderHeight,
  qnaContext,
  qnaOptions,
  viewOptions = {},
  fileCollections,
  conversation,
  onNewConversation,
  onQnaContextChange,
  onReferenceDocumentClick,
  onViewOptionsChange,
  onError,
  error,
}) => {
  const { hasPermission, hasAnyPermission } = useUserContext();
  const { t } = useTranslation();

  const tpRef = useRef();
  const bpRef = useRef();
  const optionsMenuAnchor = useRef();

  const { height: tpHeight } = useSizing(tpRef);
  const { height: bpHeight } = useSizing(bpRef);

  const [maxChatWindowSize, setMaxChatWindowSize] = useState(
    window.innerHeight - tpHeight - bpHeight - appHeaderHeight,
  );
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);

  useEffect(() => {
    setMaxChatWindowSize(
      window.innerHeight - tpHeight - bpHeight - appHeaderHeight,
    );
  }, [tpHeight, bpHeight]);

  const handleError = (error) => {
    onError(error);
  };

  const showOptionsMenu = () => {
    setOptionsMenuOpen(true);
  };

  const toggleConversationView = () => {
    onViewOptionsChange({
      ...viewOptions,
      viewConversations: !viewOptions.viewConversations,
    });
    setOptionsMenuOpen(false);
  };

  const toggleSearchOptionsView = () => {
    onViewOptionsChange({
      ...viewOptions,
      viewSearchOptions: !viewOptions.viewSearchOptions,
    });
    setOptionsMenuOpen(false);
  };

  return (
    <Stack
      sx={{ height: '100%', zIndex: 0 }}
      wrap="nowrap"
      display={{
        xs:
          viewOptions.viewConversations || viewOptions.viewSearchOptions
            ? 'none'
            : 'flex',
        md: 'flex',
      }}
      width={{ xs: '100%', md: '100%' }}
    >
      {error && <Alert severity="error">{error.message}</Alert>}
      <Stack
        sx={{
          flexGrow: 0,
        }}
        ref={tpRef}
        direction={'row'}
        justifyContent={'space-between'}
      >
        <QnaContext
          qnaContext={qnaContext}
          fileCollections={fileCollections}
          onChange={onQnaContextChange}
          onError={handleError}
        />
        {hasAnyPermission([
          'qna:options',
          'qna:conversation',
          'qna:conversation:new',
        ]) && (
          <IconButton ref={optionsMenuAnchor} onClick={showOptionsMenu}>
            <MenuOutlined />
          </IconButton>
        )}
      </Stack>
      <Box
        sx={{
          flexGrow: '1 !important',
          maxHeight: `${maxChatWindowSize}px`,
        }}
      >
        <ChatWindow
          appHeaderHeight={appHeaderHeight}
          maxHeight={maxChatWindowSize}
          qnaContext={qnaContext}
          qnaOptions={qnaOptions}
          conversation={conversation}
          onNewConversation={onNewConversation}
          onError={handleError}
          onReferenceDocumentClick={onReferenceDocumentClick}
        />
      </Box>
      <Box sx={{ flexGrow: 0 }} ref={bpRef}>
        <TermsAndConditions>{t('pages.qna.infoText')}</TermsAndConditions>
      </Box>
      <Menu
        anchorEl={optionsMenuAnchor?.current}
        open={optionsMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOptionsMenuOpen(false)}
      >
        {hasPermission('qna:conversation') && (
          <MenuItem onClick={toggleConversationView}>
            {viewOptions.viewConversations && (
              <ListItemIcon>
                <Done fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText>
              {t('pages.qna.leftPanel.optionsMenu.showMyConversations')}
            </ListItemText>
          </MenuItem>
        )}
        {hasPermission('qna:options') && (
          <MenuItem onClick={toggleSearchOptionsView}>
            {viewOptions.viewSearchOptions && (
              <ListItemIcon>
                <Done fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText>
              {t('pages.qna.leftPanel.optionsMenu.showSearchOptions')}
            </ListItemText>
          </MenuItem>
        )}
        <Divider />
        {hasPermission('qna:conversation:new') && (
          <MenuItem disabled>
            <ListItemText>
              {t('pages.qna.leftPanel.optionsMenu.newConversation')}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
};

LeftPanel.propTypes = {
  appHeaderHeight: PropTypes.number,
  qnaContext: PropTypes.object,
  qnaOptions: PropTypes.object,
  viewOptions: PropTypes.object,
  fileCollections: PropTypes.array,
  conversation: PropTypes.object,
  onNewConversation: PropTypes.func,
  onQnaContextChange: PropTypes.func,
  onViewOptionsChange: PropTypes.func,
  onReferenceDocumentClick: PropTypes.func,
  onError: PropTypes.func,
  error: PropTypes.object,
};

export default LeftPanel;
