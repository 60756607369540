/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const PrimaryLine = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const SecondaryLine = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.light,
  fontSize: 'small',
}));
