/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { FormControl, FormLabel, TextField } from '@mui/material';

const NumberField = ({
  label,
  value,
  required,
  disabled,
  error,
  min,
  max,
  step,
  onChange,
}) => {
  const handleChange = (event) => {
    const parsedValue =
      step === 1
        ? parseInt(event.target.value)
        : parseFloat(event.target.value);
    onChange(parsedValue);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <FormLabel required={required}>{label}</FormLabel>
      <TextField
        sx={{ marginTop: '0' }}
        type="number"
        value={`${value}`}
        label=""
        fullWidth
        margin="dense"
        required={required}
        size="small"
        inputProps={{ min, max, step }}
        disabled={disabled}
        error={error !== undefined}
        onChange={handleChange}
        helperText={error}
      />
    </FormControl>
  );
};

NumberField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

export default NumberField;
