/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './app';
import './app.css';
import './i18n';
import {
  AUTHORITY,
  CLIENT_ID,
  LOGOUT_REDIRECT_URL,
  REDIRECT_URL,
  SCOPE,
} from '#config/auth.config';
import { UserContextProvider } from '#contexts/userContext';
import { ToastProvider } from '#contexts/providers/toast.provider';

const root = ReactDOM.createRoot(document.getElementById('root'));

const oidcConfig = {
  authority: AUTHORITY,
  client_id: CLIENT_ID,
  scope: SCOPE,
  redirect_uri: REDIRECT_URL,
  post_logout_redirect_uri: LOGOUT_REDIRECT_URL,
};

const handleSignin = (state) => {
  if (state?.state !== undefined) {
    window.location.replace(atob(state.state));
  } else {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};

root.render(
  <React.StrictMode>
    <ToastProvider>
      <AuthProvider {...oidcConfig} onSigninCallback={handleSignin}>
        <UserContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UserContextProvider>
      </AuthProvider>
    </ToastProvider>
  </React.StrictMode>,
);
