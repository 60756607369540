/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Box, styled } from '@mui/material';
import { renderToStaticMarkup } from 'react-dom/server';
import BackgroundImageSvg from '#assets/backgroundImage.asset';

export const MainBox = styled(Box)(() => ({
  display: 'flex',
  minHeight: '100%',
  width: '100%',
  backgroundImage: `url(data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<BackgroundImageSvg />),
  )})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '180px',
}));

export const ScrollBox = styled(Box)({
  pb: { xs: 9, md: 5 },
  overflowY: 'auto',
  overflowX: 'hidden',
  alignSelf: 'stretch',
  flexGrow: 1,
});
