/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import * as React from 'react';

const BackgroundImageSvg = () => (
  // replace this with customer provided background image
  <svg
    width="90%"
    height="500"
    viewBox="0 0 1200 643"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M421.039 219.14C341.746 25.7117 22.4308 153.543 1.00012 140.087V643H971.805C975.639 640.265 979.195 637.06 982.467 633.44C1092.32 633.661 1173.74 634 1200 634V473C1006.79 485.483 1018.95 382.292 851.794 352.017C684.635 321.741 500.332 412.568 421.039 219.14Z"
      fill="#0052CC"
      fillOpacity="0.05"
    />
    <path
      d="M395.469 129.586C321.003 -100.414 21.1271 51.5862 1.00122 35.5862V633.586H912.705C979.12 573.586 956.981 323.586 799.999 287.586C643.017 251.586 469.935 359.586 395.469 129.586Z"
      fill="#E6EEFA"
      fillOpacity="0.5"
      stroke="#E6EEFA"
      strokeOpacity="0.5"
    />
  </svg>
);

export default BackgroundImageSvg;
