/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { userAuthApiHttpClient } from './httpClients.';

const userAuthApis = {
  getUsersForRole: async (tenantId, roleId) => {
    const response = await userAuthApiHttpClient.get(
      `/api/tenants/${tenantId}/roles/${roleId}/userRoles`,
    );
    const { data } = response;
    return data;
  },

  addUsersToRole: async (tenantId, roleId, users) => {
    const response = await userAuthApiHttpClient.post(
      `/api/tenants/${tenantId}/roles/${roleId}/users`,
      { userIds: users },
    );

    const { data } = response;
    return data;
  },

  deleteUserRole: async (tenantId, roleId, userId) => {
    const response = await userAuthApiHttpClient.delete(
      `/api/tenants/${tenantId}/users/${userId}/roles/${roleId}/userRole`,
    );
    const { data } = response;
    return data;
  },
};

export default userAuthApis;
