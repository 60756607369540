/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Pagination,
  CardActions,
  ButtonGroup,
  IconButton,
  Divider,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Cancel, FileDownload } from '@mui/icons-material';
import { usePdf } from '@mikecousins/react-pdf';
import { Box } from '@mui/material';

const PdfViewer = ({ url, referenceDocument, onClose, onDownload }) => {
  const theme = useTheme();
  const canvasRef = useRef(null);
  const isExtaSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [page, setPage] = useState(referenceDocument.startPageNo);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(false);

  const onDocumentLoadSuccess = (document) => {
    if (document) {
      setTotalPages(document._pdfInfo?.numPages);
    }
  };

  const onDocumentLoadFail = (e) => {
    setError(true);
  };

  const { pdfDocument } = usePdf({
    file: url,
    page,
    canvasRef,
    scale: isExtaSmall ? 0.04 : isSmall ? 1.3 : 1.4,
    onDocumentLoadSuccess,
    onDocumentLoadFail,
    withCredentials: true,
  });

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  return (
    <Card>
      <CardHeader
        title={referenceDocument.fileName}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => onDownload(referenceDocument)}
              size="small"
            >
              <FileDownload />
            </IconButton>
            <IconButton onClick={onClose} size="small">
              <Cancel />
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <CardContent sx={{ padding: 0 }}>
        {!pdfDocument && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '80vh' }}
          >
            {error ? 'Failed to load document' : <CircularProgress />}
          </Grid>
        )}
        {Boolean(pdfDocument && pdfDocument.numPages) && (
          <Box sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
            <canvas ref={canvasRef} />
          </Box>
        )}
      </CardContent>
      <Divider />
      <CardActions sx={{ justifyContent: 'center' }}>
        {Boolean(pdfDocument && pdfDocument.numPages) && (
          <Pagination
            count={totalPages}
            variant="outlined"
            color="primary"
            onChange={handlePageChange}
            defaultPage={page || 1}
          />
        )}
      </CardActions>
    </Card>
  );
};

PdfViewer.propTypes = {
  url: PropTypes.string,
  referenceDocument: PropTypes.object,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
};
export default PdfViewer;
