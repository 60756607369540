/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React from 'react';
import { Box } from '@mui/material';
import { useUserContext } from '#contexts/userContext';
import Tenant from '#components/tenant';

const TenantPage = () => {
  const { user } = useUserContext();

  return (
    <Box sx={{ width: '100%' }}>
      <Tenant tenantId={user?.tenantId} />
    </Box>
  );
};

export default TenantPage;
