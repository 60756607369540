/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

export const HOME = '/';
export const QNA = '/';
export const CHAT_HISTORY = '/chat-history';
export const READING_ASSISTANT = '/reading-assistant';
export const MORE_TOOLS = '/more-tools';
export const CONFIGURE = '/configure';
export const TENANT = '/configure/tenant';
export const FILE_COLLECTIONS = '/configure/file-collections';
export const NEW_FILE_COLLECTION = `${FILE_COLLECTIONS}/create-new`;
export const EDIT_FILE_COLLECTION = `${FILE_COLLECTIONS}/:fileCollectionId`;
export const NEW_DATA_SOURCE = `${FILE_COLLECTIONS}/:fileCollectionId/dataSources/create-new`;
export const EDIT_DATA_SOURCE = `${FILE_COLLECTIONS}/:fileCollectionId/dataSources/:dataSourceId`;
export const ROLES = '/configure/roles';
export const NEW_ROLE = `${ROLES}/create-new`;
export const EDIT_ROLE = `${ROLES}/:roleId`;
export const AUDIT_RECORDS = '/configure/audit-records';
export const UNAUTHORIZED = '/unauthorized';
export const ERROR = '/error';
export const FILE_PROCESSING_STATES = '/configure/file-processing-states';
