/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

const muiOutlinedInput = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.spacing(0),
    }),
    input: ({ theme }) => ({
      lineHeight: theme.typography.pxToRem(22),
      fontSize: theme.typography.pxToRem(14),
      height: 'auto',
      padding: theme.spacing(1, 2),
      '& p': {
        lineHeight: theme.typography.pxToRem(22),
        fontSize: theme.typography.pxToRem(14),
        height: 'auto',
      },
    }),
  },
};

export default muiOutlinedInput;
