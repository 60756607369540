/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import * as React from 'react';
const EnterpriseIconSvg = (props) => (
  <svg
    width={21}
    height={24}
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0793 0.542756C10.3884 0.365305 10.7684 0.365305 11.0775 0.542756L20.1007 5.72268C20.4121 5.90145 20.6041 6.2331 20.6041 6.59216C20.6041 6.95123 20.4121 7.28288 20.1007 7.46164L11.0775 12.6416C10.7684 12.819 10.3884 12.819 10.0793 12.6416L1.05616 7.46164C0.744756 7.28288 0.552734 6.95123 0.552734 6.59216C0.552734 6.2331 0.744756 5.90145 1.05616 5.72268L10.0793 0.542756ZM3.56902 6.59216L10.5784 10.6161L17.5878 6.59216L10.5784 2.56826L3.56902 6.59216Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93633 8.68303C7.212 9.16323 7.0462 9.77599 6.566 10.0517L3.56902 11.7721L10.5784 15.796L17.5878 11.7721L14.5908 10.0517C14.1106 9.77599 13.9448 9.16323 14.2205 8.68303C14.4961 8.20283 15.1089 8.03703 15.5891 8.3127L20.1007 10.9027C20.4121 11.0814 20.6041 11.4131 20.6041 11.7721C20.6041 12.1312 20.4121 12.4629 20.1007 12.6416L11.0775 17.8216C10.7684 17.999 10.3884 17.999 10.0793 17.8216L1.05616 12.6416C0.744756 12.4629 0.552734 12.1312 0.552734 11.7721C0.552734 11.4131 0.744756 11.0814 1.05616 10.9027L5.56771 8.3127C6.04791 8.03703 6.66066 8.20283 6.93633 8.68303Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93633 13.8632C7.212 14.3434 7.0462 14.9562 6.566 15.2318L3.56902 16.9523L10.5784 20.9762L17.5878 16.9523L14.5908 15.2318C14.1106 14.9562 13.9448 14.3434 14.2205 13.8632C14.4961 13.383 15.1089 13.2172 15.5891 13.4929L20.1007 16.0828C20.4121 16.2616 20.6041 16.5933 20.6041 16.9523C20.6041 17.3114 20.4121 17.643 20.1007 17.8218L11.0775 23.0017C10.7684 23.1792 10.3884 23.1792 10.0793 23.0017L1.05616 17.8218C0.744756 17.643 0.552734 17.3114 0.552734 16.9523C0.552734 16.5933 0.744756 16.2616 1.05616 16.0828L5.56771 13.4929C6.04791 13.2172 6.66066 13.383 6.93633 13.8632Z"
      fill="currentColor"
    />
  </svg>
);
export default EnterpriseIconSvg;
