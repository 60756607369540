/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { FormControl, FormLabel, MenuItem, TextField } from '@mui/material';

const SelectField = ({
  label,
  value = '',
  options = [],
  required,
  disabled,
  error,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <FormLabel required={required}>{label}</FormLabel>
      <TextField
        select
        value={value}
        label=""
        fullWidth
        margin="dense"
        required={required}
        size="small"
        disabled={disabled}
        error={error !== undefined}
        helperText={error}
        sx={{ marginTop: '0' }}
        onChange={handleChange}
      >
        {options.map((option) =>
          typeof option === 'string' ? (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ) : (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ),
        )}
      </TextField>
    </FormControl>
  );
};

SelectField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectField;
