import React from 'react';
import { Grid } from '@mui/material';
import ConfigureOptions from '#components/configureOptions';

const ConfigurePage = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems={'flex-start'}
      sx={{ height: '100%' }}
      spacing={1}
    >
      <Grid item>
        <ConfigureOptions />
      </Grid>
    </Grid>
  );
};

export default ConfigurePage;
