/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { coreApiHttpClient } from './httpClients.';

const auditApi = {
  getAuditRecords: async (tenantId, filters) => {
    const response = await coreApiHttpClient.post(
      `/api/tenants/${tenantId}/auditRecords`,
      filters,
    );
    const { data } = response;
    return data;
  },
};

export default auditApi;
