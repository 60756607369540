/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import palette from './speciphicTheme/palette';
import fontWeights from './fontWeights';

const typography = {
  fontFamily: 'Inter',
  textTransform: 'none',
  fontSize: 14,
  htmlFontSize: 14,
  h1: {
    color: palette.text.primary,
    fontSize: '3.125rem',
    fontWeight: fontWeights.semiBold,
  },
  h2: {
    color: palette.text.primary,
    fontSize: '1.875',
    fontWeight: fontWeights.semiBold,
  },
  h3: {
    color: palette.text.primary,
    fontSize: '1.562rem',
    fontWeight: fontWeights.medium,
  },
  h4: {
    color: palette.text.primary,
    fontSize: '1rem',
    fontWeight: fontWeights.regular,
  },
  h5: {
    color: palette.text.primary,
    fontSize: '0.937rem',
    fontWeight: fontWeights.medium,
  },
  h6: {
    color: palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: fontWeights.regular,
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: fontWeights.regular,
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: fontWeights.regular,
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: fontWeights.regular,
    textTransform: 'none',
  },
  body1: {
    color: palette.text.secondary,
    fontSize: '0.96rem',
    fontWeight: fontWeights.regular,
  },
  body2: {
    color: `${palette.text.light} !important`,
    fontSize: '0.875rem',
    fontWeight: fontWeights.regular,
  },
  caption: {
    color: palette.text.caption,
    fontSize: '0.625rem',
    fontWeight: fontWeights.regular,
  },
};

export default typography;
