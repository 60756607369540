/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Grid,
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import BooleanField from '#components/booleanField';
import TextField from '#components/textField';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useToastContext } from '#contexts/providers/toast.provider';

const DataStoreParameters = ({ tenant, editable }) => {
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  const handleCancelClick = () => {
    setErrors({});
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasError = false;
    setError();
    if (!hasError) {
      setErrors({});
      try {
        //await parameterApi.saveDataStoreParameters(tenant.id, {});
        pushToast({
          message: t('components.dataStoreParameters.toasts.saved'),
          severity: 'success',
        });
      } catch (error) {
        setError(error);
      }
    } else {
      setErrors(errors);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t('components.dataStoreParameters.header')}
        subheader={error && <Alert severity="error">{error.message}</Alert>}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.dataStoreParameters.fields.useDefaultDataStore',
                )}
                value={tenant?.useDefaultDataStore}
                disabled={!editable}
                required
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t('components.dataStoreParameters.fields.dbSchema')}
                value={tenant?.dbSchema}
                disabled={!editable}
                required
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {hasChanged && (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button size="small" onClick={handleCancelClick}>
              {t('components.dataStoreParameters.actions.cancel')}
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSaveClick}
            >
              {t('components.dataStoreParameters.actions.save')}
            </Button>
          </CardActions>
        )}
      </Collapse>
    </Card>
  );
};

DataStoreParameters.propTypes = {
  tenant: PropTypes.object,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
};

export default DataStoreParameters;
