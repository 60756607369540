/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { roleAndPermissionApi, fileCollectionApi } from '#services/apis';
import { useUserContext } from '#contexts/userContext';
import BooleanField from '#components/booleanField';
import { useToastContext } from '#contexts/providers/toast.provider';

const RoleFileCollections = ({ role = {}, onChange, editable }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { pushToast } = useToastContext();

  const [open, setOpen] = useState(true);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [fileCollections, setFileCollections] = useState([]);
  const [roleFileCollections, setRoleFileCollections] = useState([]);
  const [remainingFileCollections, setRemainingFileCollections] = useState([]);
  const [checked, setChecked] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [restrictFileCollections, setRestrictFileCollections] = useState(
    role?.restrictFileCollections,
  );

  useEffect(() => {
    const loadFileCollections = async () => {
      try {
        setLoading(true);
        const response = await fileCollectionApi.getFileCollections(
          user.tenantId,
          false,
        );
        setFileCollections(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.tenantId) {
      loadFileCollections();
    }
  }, [user?.tenantId]);

  useEffect(() => {
    if (role?.fileCollections) {
      const selected = fileCollections.filter(
        (fc) =>
          role?.fileCollections.findIndex(
            (fc1) => fc1.fileCollectionId === fc.id,
          ) > -1,
      );
      setRoleFileCollections(selected);
    }
  }, [fileCollections, role?.fileCollections]);

  useEffect(() => {
    const remaining = fileCollections.filter(
      (fc) => roleFileCollections.findIndex((fc1) => fc1.id === fc.id) < 0,
    );
    setRemainingFileCollections(remaining);
  }, [fileCollections, roleFileCollections]);

  const handleToggle = (fc) => () => {
    const currentIndex = checked.indexOf(fc.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(fc.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleMoveRight = () => {
    const selected = remainingFileCollections.filter((fc) =>
      checked.includes(fc.id),
    );
    setRoleFileCollections([...roleFileCollections, ...selected]);
    setChecked([]);
    setHasChanged(true);
  };

  const handleMoveLeft = () => {
    const unselected = roleFileCollections.filter(
      (fc) => !checked.includes(fc.id),
    );
    setRoleFileCollections([...unselected]);
    setChecked([]);
    setHasChanged(true);
  };

  const onRestrictFileCollectionsChange = (value) => {
    setRestrictFileCollections(value);
    setChecked([]);
    setRoleFileCollections([]);
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    setError();
    const selected = fileCollections.filter(
      (fc) =>
        (role?.fileCollections || []).findIndex(
          (fc1) => fc1.fileCollectionId === fc.id,
        ) > -1,
    );
    setRoleFileCollections(selected);
    setRestrictFileCollections(role.restrictFileCollections);
    setHasChanged(false);
  };

  const handleSaveClick = async () => {
    if (restrictFileCollections) {
      if (roleFileCollections.length === 0) {
        setError(
          t('components.roleFileCollections.errors.selectFileCollections'),
        );
        return;
      }
    }
    setIsSaving(true);
    try {
      const rfc = roleFileCollections.map((fc) => {
        return {
          roleId: role.id,
          fileCollectionId: fc.id,
          restrictDataSources: false,
        };
      });
      await roleAndPermissionApi.saveRoleFileCollections(
        user.tenantId,
        role.id,
        restrictFileCollections,
        rfc,
      );
      pushToast({
        message: t('components.roleFileCollections.toasts.saved'),
        severity: 'success',
      });
      setHasChanged(false);
      setError();
      onChange({ ...role, restrictFileCollections, fileCollections: rfc });
    } catch (error) {
      setError(error);
    }
    setIsSaving(false);
  };

  const fcList = (items, header) => (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardHeader title={header} />
      <Divider />
      <List dense role="list" sx={{ maxHeight: '400px', overflowY: 'auto' }}>
        {items.map((fc) => {
          return (
            <ListItemButton
              key={fc.id}
              role="listitem"
              onClick={handleToggle(fc)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(fc.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={fc.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography variant="h3">
            {t('components.roleFileCollections.header')}
          </Typography>
        }
        subheader={error && <Alert severity="error">{error.message}</Alert>}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        {loading ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '80vh' }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <CardContent>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={12}>
                <BooleanField
                  label={t(
                    'components.roleFileCollections.fields.restrictFileCollections',
                  )}
                  value={restrictFileCollections}
                  required
                  disabled={!editable}
                  onChange={onRestrictFileCollectionsChange}
                />
              </Grid>
              {restrictFileCollections && (
                <Grid item xs={4}>
                  {fcList(
                    remainingFileCollections,
                    t('components.roleFileCollections.fields.remaining'),
                  )}
                </Grid>
              )}
              {restrictFileCollections && (
                <Grid item>
                  <Card elevation={0}>
                    <CardContent>
                      <CardHeader />
                      <Grid container direction="column" alignItems="center">
                        <Button
                          sx={{ my: 0.5 }}
                          variant="outlined"
                          size="small"
                          onClick={handleMoveRight}
                          //disabled={leftChecked.length === 0}
                          aria-label="move selected right"
                        >
                          &gt;
                        </Button>
                        <Button
                          sx={{ my: 0.5 }}
                          variant="outlined"
                          size="small"
                          onClick={handleMoveLeft}
                          //disabled={rightChecked.length === 0}
                          aria-label="move selected left"
                        >
                          &lt;
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {restrictFileCollections && (
                <Grid item xs={4}>
                  {fcList(roleFileCollections, 'Selected')}
                </Grid>
              )}
            </Grid>
          </CardContent>
        )}
        <Divider />
        {isSaving ? (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <CircularProgress />
          </CardActions>
        ) : (
          hasChanged && (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleCancelClick}>
                {t('components.roleFileCollections.actions.cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSaveClick}
              >
                {t('components.roleFileCollections.actions.save')}
              </Button>
            </CardActions>
          )
        )}
      </Collapse>
    </Card>
  );
};

RoleFileCollections.propTypes = {
  role: PropTypes.object,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
};

export default RoleFileCollections;
