/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  StyledDataGrid,
  StyledButton,
  StyledGridToolbarQuickFilter,
  StyledSearchIcon,
  StyledToolbarGrid,
} from './datagridTable.styled';

const DataGridTable = ({
  rows,
  columns,
  header,
  checkboxSelection,
  allowNewRecord,
  onNewRecord,
  newRecordLabel,
  onRowDoubleClick,
  rowHeight,
  getRowId,
  error,
}) => {
  const { t } = useTranslation();

  const handdleDoubleClick = (row) => {
    if (onRowDoubleClick) {
      onRowDoubleClick(row);
    }
  };

  const handdleNewRecordClick = (e) => {
    if (onNewRecord) {
      onNewRecord();
    }
  };

  const CustomToolbar = () => {
    return (
      <Box sx={{ marginY: 2 }}>
        <StyledToolbarGrid container spacing={2}>
          <Grid item xs={12} sm={6} md={'auto'}>
            <Typography variant="h3" sx={{ paddingLeft: '10px' }}>
              {header}
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
          </Grid>
          <Grid container item xs={12} sm={6} md={'auto'}>
            {allowNewRecord && (
              <Grid item xs={12} sm={6} md={'auto'}>
                <StyledButton
                  variant="contained"
                  size="medium"
                  onClick={handdleNewRecordClick}
                >
                  {newRecordLabel || t('components.dataGrid.actions.create')}
                </StyledButton>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={'auto'}>
              <StyledGridToolbarQuickFilter placeholder="Search" />
            </Grid>
          </Grid>
        </StyledToolbarGrid>
      </Box>
    );
  };

  return (
    <StyledDataGrid
      rows={rows}
      checkboxSelection={checkboxSelection}
      columns={columns}
      disableColumnMenu
      disableSelectionOnClick
      rowHeight={rowHeight}
      getRowId={getRowId}
      onRowDoubleClick={handdleDoubleClick}
      slots={{
        toolbar: CustomToolbar,
        quickFilterIcon: StyledSearchIcon,
      }}
      density="compact"
    />
  );
};
DataGridTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  header: PropTypes.string,
  checkboxSelection: PropTypes.bool,
  allowNewRecord: PropTypes.bool,
  onNewRecord: PropTypes.func,
  newRecordLabel: PropTypes.string,
  onRowDoubleClick: PropTypes.func,
  error: PropTypes.object,
  rowHeight: PropTypes.number,
  getRowId: PropTypes.func,
};

export default DataGridTable;
