/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Parser as HtmlParser } from 'html-to-react';

const TermsAndConditionPopup = ({ isOpen, onAgree, termsAndConditions }) => {
  const htmlParser = new HtmlParser();
  const { t } = useTranslation();

  const handleAgree = () => {
    onAgree();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h3">
          {t('components.termsAndConditions.title')}
        </Typography>
      </DialogTitle>
      <DialogContent divider={true}>
        <DialogContentText>
          <div>{htmlParser.parse(termsAndConditions)}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAgree} variant="contained">
          {t('components.termsAndConditions.agreeButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TermsAndConditionPopup.propTypes = {
  isOpen: PropTypes.bool,
  onAgree: PropTypes.func,
  termsAndConditions: PropTypes.string,
};

export default TermsAndConditionPopup;
