/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import TextField from '#components/textField';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const MetaData = ({ metaData, editable = true, onMetaDataChange, errors }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);

  const addMetaData = () => {
    onMetaDataChange([...metaData, { name: '', value: '' }]);
  };

  const removeMetaData = (idx) => {
    metaData.splice(idx, 1);
    onMetaDataChange([...metaData]);
  };

  const onChange = (key, value, index) => {
    onMetaDataChange(
      metaData.map((obj, idx) => {
        if (index === idx) {
          obj[key] = value;
        }
        return obj;
      }),
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t(`components.metaData.header`)}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        <CardContent>
          <Grid container>
            <Grid item pt={1} pb={2}>
              <IconButton color="primary" onClick={() => addMetaData()}>
                <AddIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {metaData.length > 0 &&
              metaData.map((obj, idx) => (
                <Grid item container spacing={1} key={idx}>
                  <Grid item xs={6} md={3}>
                    <TextField
                      label={t(`components.metaData.fields.key`)}
                      value={metaData[idx]?.name}
                      onChange={(value) => onChange('name', value, idx)}
                      disabled={!editable}
                      error={errors[idx] && errors[idx].key}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      label={t(`components.metaData.fields.value`)}
                      value={metaData[idx]?.value}
                      onChange={(value) => onChange('value', value, idx)}
                      disabled={!editable}
                      error={errors[idx] && errors[idx].value}
                    />
                  </Grid>
                  <Grid item alignContent={'flex-end'} mb={1}>
                    <RemoveCircleIcon
                      onClick={() => removeMetaData(idx)}
                      color="primary"
                      fontSize="small"
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </CardContent>
        <Divider />
      </Collapse>
    </Card>
  );
};

MetaData.propTypes = {
  metaData: PropTypes.array,
  editable: PropTypes.bool,
  onMetaDataChange: PropTypes.func,
  errors: PropTypes.array,
};

export default MetaData;
