/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { FormControl, FormLabel, Switch } from '@mui/material';

const BooleanField = ({
  label,
  value = false,
  required,
  disabled,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <FormLabel required={required}>{label}</FormLabel>
      <Switch
        checked={value}
        size="small"
        onChange={handleChange}
        disabled={disabled}
      />
    </FormControl>
  );
};

BooleanField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default BooleanField;
