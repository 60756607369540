/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { formatDistanceToNow } from 'date-fns';
import UserAvatar from '#components/userAvatar/userAvatar.component';
import { MessageTime, MessageText } from './userMessage.styled';

const UserMessage = ({ userMessage, messageTime }) => {
  return (
    <Stack direction={'row'} spacing={2}>
      <UserAvatar size={32} />
      <Stack direction={'column'} justifyContent={'center'}>
        {messageTime && (
          <MessageTime>{formatDistanceToNow(messageTime)}</MessageTime>
        )}
        <MessageText variant="body1">{userMessage}</MessageText>
      </Stack>
    </Stack>
  );
};

UserMessage.propTypes = {
  userMessage: PropTypes.string,
  messageTime: PropTypes.any,
};

export default UserMessage;
