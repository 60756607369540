/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { ErrorBoundary } from 'react-error-boundary';
import { useEffect, useState } from 'react';
import { CircularProgress, Grid, ThemeProvider } from '@mui/material';
import { applicationTheme } from './themes';
import AppLayout from '#components/appLayout';
import ErrorFallbackPage from '#pages/errorFallbackPage';
import TermsAndConditionsPopUp from '#components/termsAndConditionsPopUp';
import { CURRENT_THEME } from '#config/ui.config';
import { useUserContext } from '#contexts/userContext';

const App = () => {
  const userContext = useUserContext();
  const currentTheme = applicationTheme(CURRENT_THEME);

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [termsAndConditionsAgreed, setTermsAndConditionsAgreed] =
    useState(false);

  useEffect(() => {
    if (userContext.authError) {
      userContext.signinRedirect();
    }
  }, [userContext, userContext.authError]);

  useEffect(() => {
    if (!userContext?.isLoading && !userContext?.isAuthenticated) {
      userContext.signinRedirect();
    }
  }, [userContext?.isAuthenticated, userContext?.isLoading]);

  useEffect(() => {
    if (userContext?.tenant) {
      const { termsAndConditions } = userContext.tenant;
      const agreedToTnC = window.localStorage.getItem(
        'agreedToTermsAndConditions',
      );
      if (termsAndConditions && !agreedToTnC) {
        setShowTermsAndConditions(true);
      } else {
        setTermsAndConditionsAgreed(true);
      }
    }
  }, [userContext?.tenant]);

  const handleAgreeTermsAndConditions = () => {
    localStorage.setItem('agreedToTermsAndConditions', true);
    setShowTermsAndConditions(false);
    setTermsAndConditionsAgreed(true);
  };

  const handleApplicationError = (error, info) => {
    console.error('An error has occurred in the application.');
    console.error(error, info);
  };

  if (
    userContext?.isLoading ||
    (!userContext?.user && !userContext?.serverError)
  ) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems={'center'}
        sx={{ height: '100%' }}
        spacing={1}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (userContext?.serverError) {
    return <ErrorFallbackPage error={userContext?.serverError} />;
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <ErrorBoundary
        FallbackComponent={ErrorFallbackPage}
        onError={handleApplicationError}
      >
        {termsAndConditionsAgreed && <AppLayout />}
        <TermsAndConditionsPopUp
          isOpen={showTermsAndConditions}
          onAgree={handleAgreeTermsAndConditions}
          termsAndConditions={userContext?.tenant?.termsAndConditions}
        />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
