/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createTheme } from '@mui/material';

let { palette } = createTheme();

palette = {
  ...palette,
  primary: {
    main: '#003e51',
    light: '#005f7c',
    dark: '#002531',
  },
  secondary: {
    main: '#EBEBEB',
    light: '#F5F5F5',
  },
  text: {
    primary: '#000000',
    secondary: '#666666',
    light: '#b3b3b3',
    caption: '#D0D0D0',
    controls: '#333333',
    disabled: '#999999',
  },
  border: {
    light: '#D0D0D0',
  },
  background: {
    white: '#FFFFFF',
  },
};

export default palette;
