export const AZURE_BLOB_PARAMS = [
  {
    name: 'accountName',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'containerName',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'accessKey',
    defaultValue: '',
    required: true,
    type: 'password',
  },
  {
    name: 'timeout',
    defaultValue: 6000,
    required: false,
    type: 'number',
  },
  {
    name: 'delay',
    defaultValue: 60,
    required: false,
    type: 'number',
  },
  {
    name: 'schedulerCronExpression',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'includeFileRegEx',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'excludeFileRegEx',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'includePath',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'excludePath',
    defaultValue: null,
    required: false,
    type: 'text',
  },
];

export const AWS_S3_PARAMS = [
  {
    name: 'bucketNameOrArn',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'region',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'accessKey',
    defaultValue: '',
    required: true,
    type: 'password',
  },
  {
    name: 'secretKey',
    defaultValue: '',
    required: true,
    type: 'password',
  },
  {
    name: 'delay',
    defaultValue: 60,
    required: false,
    type: 'number',
  },
  {
    name: 'schedulerCronExpression',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'includeFileRegEx',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'excludeFileRegEx',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'includePath',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'excludePath',
    defaultValue: null,
    required: false,
    type: 'text',
  },
];

export const MS_SITE_PARAMS = [
  {
    name: 'siteId',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'siteName',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'basePath',
    defaultValue: '/',
    required: false,
    type: 'text',
  },
  {
    name: 'authTenantId',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'accessTokenUrl',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'authClientId',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'authClientSecret',
    defaultValue: '',
    required: true,
    type: 'password',
  },
  {
    name: 'authScope',
    defaultValue: '',
    required: true,
    type: 'text',
  },
  {
    name: 'delay',
    defaultValue: 60,
    required: false,
    type: 'number',
  },
  {
    name: 'schedulerCronExpression',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'includeFileRegEx',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'excludeFileRegEx',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'includePath',
    defaultValue: null,
    required: false,
    type: 'text',
  },
  {
    name: 'excludePath',
    defaultValue: null,
    required: false,
    type: 'text',
  },
];
