/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { SSE } from 'sse.js';
import { coreApiHttpClient } from './httpClients.';
import { CORE_API_BASE_URL } from '#config/api.config';
import { getUsersAccessToken } from '#services/user.service';

const conversationApi = {
  getUserConversations: async (tenantId) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/user/conversations`,
    );
    const { data } = response;
    return data;
  },

  getConversationMessages: async (
    tenantId,
    fileCollectionId,
    conversationId,
  ) => {
    let url;
    if (fileCollectionId) {
      url = `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/conversation/messages`;
    } else if (conversationId) {
      url = `/api/tenants/${tenantId}/conversations/${conversationId}/messages`;
    } else {
      url = `/api/tenants/${tenantId}/conversation/messages`;
    }

    const response = await coreApiHttpClient.get(url);
    const { data } = response;
    return data;
  },

  getSseStream: async (
    tenantId,
    fileCollectionId,
    fileId,
    conversationId,
    userMessage,
    options,
    callback,
  ) => {
    const payload = {
      userMessage: userMessage,
      options,
    };

    var source = new SSE(
      `${CORE_API_BASE_URL}/api/tenants/${tenantId}/${
        fileCollectionId
          ? `filecollections/${fileCollectionId}/`
          : fileId
            ? `fileid/${fileId}/`
            : ''
      }conversations/${conversationId || 'new'}/chatCompletion/stream`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getUsersAccessToken(),
        },
        payload: JSON.stringify(payload),
      },
    );

    let timer = conversationApi.setTimer(source, callback);
    source.addEventListener('message', function (e) {
      clearTimeout(timer);
      timer = conversationApi.setTimer(source, callback);
      if (e.data) {
        try {
          const payload = JSON.parse(e.data);
          callback('message', payload);
        } catch (error) {
          console.log(error);
        }
      }
    });

    source.addEventListener('messageReferenceDocuments', function (e) {
      clearTimeout(timer);
      timer = conversationApi.setTimer(source, callback);
      if (e.data) {
        try {
          const payload = JSON.parse(e.data);
          callback('messageReferenceDocuments', payload);
        } catch (error) {
          console.log(error);
        }
      }
    });

    source.addEventListener('progressStatus', function (e) {
      clearTimeout(timer);
      timer = conversationApi.setTimer(source, callback);
      if (e.data) {
        try {
          const payload = JSON.parse(e.data);
          callback('progressStatus', payload);
        } catch (error) {
          console.log(error);
        }
      }
    });

    source.addEventListener('error', function (e) {
      clearTimeout(timer);
      source.close();
      if (e.data) {
        try {
          const payload = JSON.parse(e.data);
          callback('error', payload);
        } catch (error) {
          callback('error', { message: error.message });
        }
      } else {
        callback('error', 'An error has occured');
      }
    });

    source.addEventListener('close', function () {
      clearTimeout(timer);
      source.close();
      callback('close', {});
    });

    return source;
  },

  chatCompletion: async (
    tenantId,
    fileCollectionId,
    fileId,
    conversationId,
    userMessage,
    options,
    abortController,
  ) => {
    try {
      const url = `${CORE_API_BASE_URL}/api/tenants/${tenantId}/${
        fileCollectionId
          ? `filecollections/${fileCollectionId}/`
          : fileId
            ? `fileid/${fileId}/`
            : ''
      }conversations/${conversationId || 'new'}/chatCompletion`;

      const payload = {
        userMessage: userMessage,
        options,
      };

      const response = await coreApiHttpClient.post(url, payload, {
        signal: abortController.signal,
      });
      const { data } = response;
      return data;
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;
      const message = data.errorCode
        ? `An error occured. Error Code: ${data.errorCode}`
        : error.message;
      throw new Error(message);
    }
  },

  createConversation: async (tenantId, fileCollectionId) => {
    let url;
    if (fileCollectionId) {
      url = `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/conversation`;
    } else {
      url = `/api/tenants/${tenantId}/conversation`;
    }

    const response = await coreApiHttpClient.post(url);
    const { data } = response;
    return data;
  },

  deleteConversation: async (tenantId, conversationId) => {
    await coreApiHttpClient.delete(
      `/api/tenants/${tenantId}/conversations/${conversationId}`,
    );
  },

  deleteConversationMessage: async (tenantId, conversationId, messageId) => {
    await coreApiHttpClient.delete(
      `/api/tenants/${tenantId}/conversations/${conversationId}/messages/${messageId}`,
    );
  },

  setTimer: (source, callback) => {
    return setTimeout(() => {
      if (source.readyState !== source.CLOSED) {
        source.close();
        callback('error', 'Request timed out');
      }
    }, 60000);
  },
};

export default conversationApi;
