/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import React, { useState } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import NumberField from '#components/numberField';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MultipleSelectField from '#components/muiltipleSelectField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const LAST_STATUS_OPTIONS = ['SCANNED', 'PROCESSED', 'FAILED'];

const Filters = ({
  onFilterChange,
  fileCollections,
  dataSources,
  defaultFilters,
  defaultFileCollections = [],
  defaultDataSources = [],
}) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState(defaultFilters);
  const [selectedFileCollections, setSelectedFileCollections] = useState(
    defaultFileCollections,
  );
  const [selectedDataSources, setSelectedDataSources] =
    useState(defaultDataSources);

  const handleOnFilterChange = () => {
    onFilterChange(filters, selectedFileCollections, selectedDataSources);
  };

  const handleChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleFileCollectionChange = (value) => {
    setSelectedFileCollections(value);
  };

  const handleDataSourceChange = (value) => {
    setSelectedDataSources(value);
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t('components.fileProcessingStateFilters.headers')} />
      <Divider />
      <CardContent>
        <Grid container direction="column" spacing={3}>
          <Grid item container spacing={3} justifyContent="flex-start">
            <Grid item xs={12} sm={6} md={3}>
              <MultipleSelectField
                label={t(
                  'components.fileProcessingStateFilters.fields.lastStatus',
                )}
                value={filters.lastStatuses}
                options={LAST_STATUS_OPTIONS}
                onChange={(value) => handleChange('lastStatuses', value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MultipleSelectField
                label={t(
                  'components.fileProcessingStateFilters.fields.fileCollections',
                )}
                value={selectedFileCollections}
                options={fileCollections.map((fc) => fc.name)}
                onChange={(value) => handleFileCollectionChange(value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <MultipleSelectField
                label={t(
                  'components.fileProcessingStateFilters.fields.dataSources',
                )}
                value={selectedDataSources}
                options={dataSources.map((ds) => ds.name)}
                onChange={(value) => handleDataSourceChange(value)}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t(
                    'components.fileProcessingStateFilters.fields.startScannedDate',
                  )}
                  value={filters.startScannedDate}
                  onChange={(value) => handleChange('startScannedDate', value)}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t(
                    'components.fileProcessingStateFilters.fields.endScannedDate',
                  )}
                  value={filters.endScannedDate}
                  onChange={(value) => handleChange('endScannedDate', value)}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={2} sm={2} md={2}>
              <NumberField
                label={t(
                  'components.fileProcessingStateFilters.fields.maxRecords',
                )}
                value={filters.maxRecords}
                min={1}
                step={1}
                max={5000}
                onChange={(value) => handleChange('maxRecords', value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOnFilterChange}
        >
          {t('components.fileProcessingStateFilters.actions.apply')}
        </Button>
      </CardContent>
    </Card>
  );
};

Filters.propTypes = {
  onFilterChange: PropTypes.func,
  fileCollections: PropTypes.array,
  dataSources: PropTypes.array,
  defaultFilters: PropTypes.object,
  defaultFileCollections: PropTypes.array,
  defaultDataSources: PropTypes.array,
};

export default Filters;
