/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useEffect, useState } from 'react';

/**
 * useSizing hook for use of headers and footers to maintain responsiveness
 */
const useSizing = (myRef) => {
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const handleResize = () => {
    if (myRef && myRef.current) {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleResize();
    }, 800);
    setIntervalId(intervalId);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (height !== null && width !== null) {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }, [height, width]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return { width: width || 0, height: height || 0 };
};

export default useSizing;
