/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Box, CssBaseline, useMediaQuery, useTheme } from '@mui/material';
import { MainBox, ScrollBox } from './appLayout.styled';
import AppRoutes from '#routes/appRoutes';
import AppSideNavbar from '#components/appSideNavbar';
import Appbar from '#components/appbar';
import useSizing from '#hooks/useSizing.hook';
import AppSnackbar from '#components/appSnackbar';
import { useToastContext } from '#contexts/providers/toast.provider';

const AppLayout = () => {
  const headerRef = useRef(null);
  const theme = useTheme();
  const { height: appHeaderHeight } = useSizing(headerRef);

  const isMdScreenOrLarger = useMediaQuery(theme.breakpoints.up('md', true));
  const { toasts, removeToast } = useToastContext();

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    setIsSidebarVisible(isMdScreenOrLarger);
  }, [isMdScreenOrLarger]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleToastDismiss = (toastId) => {
    removeToast(toastId);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
      }}
    >
      <CssBaseline />
      <Appbar ref={headerRef} onSidebarToggle={toggleSidebar} />
      <AppSideNavbar isVisible={isSidebarVisible} />
      <MainBox component={'main'}>
        <ScrollBox
          sx={{
            width: '100%',
            padding: '10px',
            marginTop: `${appHeaderHeight}px`,
          }}
        >
          <AppRoutes appHeaderHeight={appHeaderHeight} />
        </ScrollBox>
      </MainBox>
      {toasts &&
        toasts.length > 0 &&
        toasts.map(({ toastId, open, message, severity }) => (
          <AppSnackbar
            key={toastId}
            open={open}
            message={message}
            alertSeverity={severity}
            onClose={() => handleToastDismiss(toastId)}
          />
        ))}
    </Box>
  );
};

AppLayout.propTypes = {
  appHeaderHeight: PropTypes.number,
};

export default AppLayout;
