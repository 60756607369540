/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mui/material';
import { conversationApi } from '#services/apis';
import ChatMessage from './chatMessage.component';
import ChatInputBox from './chatInput.component';
import useSizing from '#hooks/useSizing.hook';
import { useUserContext } from '#contexts/userContext';
import { ContextInfo } from './chatWindow.styled';
import { useToastContext } from '#contexts/providers/toast.provider';

const ChatWindow = ({
  maxHeight,
  qnaContext = {},
  qnaOptions = {},
  conversation,
  onError,
  onNewConversation,
  onReferenceDocumentClick,
}) => {
  const { t } = useTranslation();
  const ibRef = useRef();
  const eocRef = useRef();
  const { user } = useUserContext();
  const { pushToast } = useToastContext();

  const [conversationMessages, setConversationMessages] = useState([]);
  const [hasMoreMessages, setHasMoreMessages] = useState();

  const [loading, setLoading] = useState(false);

  const [inprogressMessage, setInprogressMessage] = useState();
  const [streamSource, setStreamSource] = useState();
  const [abortController, setAbortController] = useState();

  const [isDisabled, setIsDisabled] = useState(false);

  const { height: ibHeight } = useSizing(ibRef);

  useEffect(() => {
    const getConversationMessages = async () => {
      setLoading(true);
      try {
        const conversationMessages =
          await conversationApi.getConversationMessages(
            user.tenantId,
            conversation.fileCollectionId,
            conversation.id,
          );
        setConversationMessages(conversationMessages?.messages || []);
        setHasMoreMessages(conversationMessages.hasMore);
      } catch (err) {
        onError(err);
      }
      setLoading(false);
    };

    if (user?.tenantId && conversation?.id) {
      if (streamSource) {
        streamSource.close();
        setStreamSource();
      }
      if (abortController) {
        abortController.abort();
        setAbortController();
      }
      if (inprogressMessage) {
        setInprogressMessage((im) => {
          return {
            ...im,
            isResponseComplete: true,
            response: `${im?.response || ''} <Cancelled>`,
          };
        });
      }
      getConversationMessages();
    } else {
      setConversationMessages([]);
    }
  }, [conversation?.id, user?.tenantId]);

  useEffect(() => {
    if (inprogressMessage && inprogressMessage.isResponseComplete) {
      setConversationMessages([
        { ...inprogressMessage },
        ...conversationMessages,
      ]);
      if (!conversation && inprogressMessage.conversationId) {
        onNewConversation(inprogressMessage.conversationId);
      }
      setInprogressMessage();
    }
  }, [inprogressMessage]);

  useEffect(() => {
    if (eocRef.current) {
      eocRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [eocRef.current, conversationMessages?.length, inprogressMessage]);

  useEffect(() => {
    if (
      loading ||
      (inprogressMessage && !inprogressMessage.isResponseComplete) ||
      !qnaContext?.searchContext
    ) {
      setIsDisabled(true);
    } else if (qnaContext?.searchContext === 'aboutFileCollection') {
      setIsDisabled(!qnaContext.fileCollectionId);
    } else {
      setIsDisabled(false);
    }
  }, [loading, inprogressMessage, qnaContext]);

  const submitUserMessage = async (userMessage) => {
    setInprogressMessage({
      userMessage,
      response: '',
      messageDateTime: new Date(),
      isResponseComplete: false,
    });
    try {
      if (qnaOptions.streamMode) {
        const source = await conversationApi.getSseStream(
          user.tenantId,
          qnaContext.fileCollectionId,
          qnaContext.fileId,
          conversation?.id,
          userMessage,
          { llm: qnaOptions.llm },
          (eventName, payLoad) => {
            const updates = { newresponse: '', responseDateTime: new Date() };
            if (eventName === 'messageReferenceDocuments') {
              updates.messageReferenceDocuments = payLoad;
            } else if (eventName === 'close') {
              updates.isResponseComplete = true;
            } else if (payLoad) {
              const { conversationId, message } = payLoad;
              updates.conversationId = conversationId;
              if (eventName === 'message') {
                updates.newresponse = message;
                updates.progressStatus = null;
              } else if (eventName === 'progressStatus') {
                updates.progressStatus = message;
              } else if (eventName === 'error') {
                updates.newresponse = message;
                updates.isResponseError = true;
                updates.isResponseComplete = true;
              }
            }

            setInprogressMessage((im) => {
              return {
                ...im,
                ...updates,
                response: `${im?.response || ''}${updates.newresponse}`,
              };
            });
          },
        );
        setStreamSource(source);
      } else {
        const abortController = new AbortController();
        setAbortController(abortController);
        const response = await conversationApi.chatCompletion(
          user.tenantId,
          qnaContext.fileCollectionId,
          qnaContext.fileId,
          conversation?.id,
          userMessage,
          {
            llm: qnaOptions.llm,
            conversationMode: qnaOptions.conversationMode,
          },
          abortController,
        );
        const { message = {}, referenceDocuments = [] } = response;
        setInprogressMessage((im) => {
          return {
            ...im,
            response: message.message,
            conversationId: message.conversationId,
            messageReferenceDocuments: referenceDocuments,
            isResponseComplete: true,
            responseDateTime: new Date(),
          };
        });
      }
    } catch (err) {
      setInprogressMessage((im) => {
        return {
          ...im,
          response: `${im?.response || ''} ${err.message}`,
          isResponseError: true,
          isResponseComplete: true,
          responseDateTime: new Date(),
        };
      });
    }
  };

  const cancelStrean = () => {
    if (streamSource) {
      streamSource.close();
      setStreamSource();
    }
    if (abortController) {
      abortController.abort();
      setAbortController();
    }
    if (inprogressMessage) {
      setInprogressMessage((im) => {
        return {
          ...im,
          isResponseComplete: true,
          response: `${im?.response || ''} <Cancelled>`,
        };
      });
    }
  };

  const deleteConversationMessage = async (messageId) => {
    try {
      setLoading(true);
      await conversationApi.deleteConversationMessage(
        user.tenantId,
        conversation.id,
        messageId,
      );
      const idx = conversationMessages.findIndex((m) => m.id === messageId);
      if (idx > -1) {
        conversationMessages.splice(idx, 1);
        setConversationMessages([...conversationMessages]);
      }
      pushToast({
        message: t('components.chatWindow.toasts.deleteConversationMessage'),
        severity: 'success',
      });
      onError();
    } catch (error) {
      onError(error);
    }
    setLoading(false);
  };

  return (
    <Stack
      justifyContent={'stretch'}
      spacing={1}
      wrap="nowrap"
      sx={{ display: 'flex', height: '100%' }}
    >
      <Box
        sx={{
          flexGrow: '1 !important',
        }}
      >
        <Stack
          spacing={1}
          direction={'column-reverse'}
          sx={{
            overflowY: 'auto',
            maxHeight: `${maxHeight - ibHeight - 38}px`,
            height: '100%',
          }}
          wrap="nowrap"
        >
          <div ref={eocRef} />
          {inprogressMessage && !inprogressMessage.isResponseComplete && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={cancelStrean}
              >
                {t('components.chatWindow.cancelStream')}
              </Button>
            </Box>
          )}
          {inprogressMessage && !inprogressMessage.isResponseComplete && (
            <ChatMessage
              message={inprogressMessage}
              onReferenceDocumentClick={onReferenceDocumentClick}
              onDelete={deleteConversationMessage}
            />
          )}
          {conversationMessages.map((m, idx) => (
            <ChatMessage
              key={idx}
              message={{ ...m, isResponseComplete: true }}
              onReferenceDocumentClick={onReferenceDocumentClick}
              onDelete={deleteConversationMessage}
            />
          ))}
          {hasMoreMessages && (
            <Button variant="outlined" sx={{ alignSelf: 'center' }}>
              {t('components.chatWindow.loadOlderMessages')}
            </Button>
          )}
        </Stack>
      </Box>
      <Box sx={{ flexGrow: '0 !important' }} ref={ibRef}>
        <Stack>
          <ChatInputBox onSubmit={submitUserMessage} disabled={isDisabled} />
          <ContextInfo>
            {t('components.chatWindow.askingAbout', {
              context:
                qnaContext.fileCollectionName ||
                t('components.chatWindow.anything'),
            })}
          </ContextInfo>
        </Stack>
      </Box>
    </Stack>
  );
};

ChatWindow.propTypes = {
  maxHeight: PropTypes.number,
  qnaContext: PropTypes.object,
  qnaOptions: PropTypes.object,
  conversation: PropTypes.object,
  onError: PropTypes.func,
  onNewConversation: PropTypes.func,
  onReferenceDocumentClick: PropTypes.func,
};

export default ChatWindow;
