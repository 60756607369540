/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const pushToast = (newToast) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { ...newToast, toastId: Date.now(), open: true },
    ]);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) =>
      prevToasts.filter((toast) => toast.toastId !== id),
    );
  };

  const toastContextValues = {
    toasts,
    pushToast,
    removeToast,
  };

  return (
    <ToastContext.Provider value={toastContextValues}>
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node,
};

const useToastContext = () => {
  return useContext(ToastContext);
};

export { ToastProvider, useToastContext };
