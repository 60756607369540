/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { roleAndPermissionApi } from '#services/apis';
import { useUserContext } from '#contexts/userContext';
import BooleanField from '#components/booleanField';
import { useToastContext } from '#contexts/providers/toast.provider';

const RolePermissions = ({ roleId, defaultValues = [], editable }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { pushToast } = useToastContext();

  const [open, setOpen] = useState(true);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [permissions, setPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState(defaultValues);
  const [prevValues, setPrevValues] = useState(defaultValues);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const getPermissions = async () => {
      try {
        setLoading(true);
        const response = await roleAndPermissionApi.getPermissions(
          user.tenantId,
        );
        const permissions = {};
        response.forEach((permission) => {
          const parts = permission.split(':');
          const section = parts.length > 1 ? parts[1] : 'general';
          const name = parts.length > 1 ? parts[0] : permission;

          if (!permissions[section]) {
            permissions[section] = [];
          }
          permissions[section].push({ name, permission });
        });
        setPermissions(permissions);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.tenantId) {
      getPermissions();
    }
  }, [user?.tenantId]);

  const handleChange = (permission) => {
    const idx = rolePermissions.indexOf(permission);
    if (idx > -1) {
      rolePermissions.splice(idx, 1);
      setRolePermissions([...rolePermissions]);
    } else {
      setRolePermissions([...rolePermissions, permission]);
    }
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    setError();
    setRolePermissions([...prevValues]);
    setHasChanged(false);
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    try {
      await roleAndPermissionApi.saveRolePermissions(
        user.tenantId,
        roleId,
        rolePermissions,
      );
      pushToast({
        message: t('components.rolePermissions.toasts.saved'),
        severity: 'success',
      });
      setPrevValues([...rolePermissions]);
      setHasChanged(false);
      setError();
    } catch (error) {
      setError(error);
    }
    setIsSaving(false);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography variant="h3">
            {t('components.rolePermissions.header')}
          </Typography>
        }
        subheader={error && <Alert severity="error">{error.message}</Alert>}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        <CardContent>
          <Grid container spacing={1}>
            {Object.keys(permissions).map((section) => (
              <Grid item xs="auto" key={section}>
                <Card variant="outlined">
                  <CardHeader
                    subheader={t(
                      `components.rolePermissions.sections.${section}`,
                    )}
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      {permissions[section].map((p) => (
                        <Grid item key={p.permission} xs="auto">
                          <BooleanField
                            label={t(
                              `components.rolePermissions.permissions.${p.name}`,
                            )}
                            value={rolePermissions.includes(p.permission)}
                            disabled={!editable}
                            onChange={(checked) =>
                              handleChange(p.permission, checked)
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <Divider />
        {isSaving ? (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <CircularProgress />
          </CardActions>
        ) : (
          hasChanged && (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleCancelClick}>
                {t('components.rolePermissions.actions.cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSaveClick}
              >
                {t('components.rolePermissions.actions.save')}
              </Button>
            </CardActions>
          )
        )}
      </Collapse>
    </Card>
  );
};

RolePermissions.propTypes = {
  roleId: PropTypes.string,
  defaultValues: PropTypes.array,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
};

export default RolePermissions;
