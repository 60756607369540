/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

const {
  REACT_APP_DEFAULT_TENANT_ID,
  REACT_APP_CORE_API_BASE_URL,
  REACT_APP_USER_AUTH_API_BASE_URL,
  REACT_APP_INGESTION_API_BASE_URL,
} = process.env;

export const DEFAULT_TENANT_ID = REACT_APP_DEFAULT_TENANT_ID;
export const CORE_API_BASE_URL = REACT_APP_CORE_API_BASE_URL;
export const USER_AUTH_API_URL = REACT_APP_USER_AUTH_API_BASE_URL;
export const INGESTION_API_BASE_URL = REACT_APP_INGESTION_API_BASE_URL;
