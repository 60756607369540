import { Typography, styled } from '@mui/material';

export const MessageTime = styled(Typography)(({ theme }) => ({
  fontSize: `.5em`,
}));

export const MessageText = styled(Typography)(({ theme }) => ({
  overflowWrap: 'anywhere',
  width: 'fit-content',
  [theme.breakpoints.down('md')]: {
    fontSize: `16px`,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: `14px`,
  },
}));
