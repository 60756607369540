/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import FileCollection from '#components/fileCollection';

const FileCollectionPage = () => {
  const { fileCollectionId } = useParams();

  return (
    <Box sx={{ width: '100%' }}>
      <FileCollection fileCollectionId={fileCollectionId} />
    </Box>
  );
};

export default FileCollectionPage;
