/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { AppBar, Box, Grid, Typography, styled } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: '50px',
  boxShadow: '0px 4px 11px 0px rgba(0, 0, 0, 0.05);',
  background: theme.palette.background.default,
  zIndex: theme.zIndex.drawer + 1,
  position: 'fixed',
}));

export const ProfileGridWrap = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  float: 'right',
});

export const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
  marginRight: theme.spacing(2),
}));

export const LogoGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
  justifyContent: 'flex-start',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));
