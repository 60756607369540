/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import * as React from 'react';

const BrandLogoSvg = (props) => (
  // replace this with customer provided brand logo
  <svg
    width={120}
    height={28}
    viewBox="0 0 120 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4963 10.0656H5.56197C5.30353 10.0656 5.08484 10.1144 4.90592 10.2118C4.74687 10.2898 4.60771 10.4068 4.48842 10.5627C4.36914 10.7187 4.27968 10.8941 4.22004 11.089C4.16039 11.2645 4.13057 11.4399 4.13057 11.6153C4.13057 11.9857 4.24986 12.3269 4.48842 12.6387C4.74687 12.9311 5.05502 13.0773 5.41287 13.0773H8.75281C9.44863 13.0773 10.055 13.1943 10.5719 13.4282C11.1087 13.6621 11.546 13.9838 11.884 14.3931C12.2419 14.8025 12.5003 15.2898 12.6593 15.8551C12.8383 16.4205 12.9277 17.0247 12.9277 17.668V17.7557C12.9277 18.399 12.8184 18.9838 12.5997 19.5101C12.4009 20.0365 12.1126 20.4848 11.7349 20.8552C11.3572 21.2256 10.9099 21.518 10.393 21.7324C9.87606 21.9468 9.32935 22.054 8.75281 22.054H1.53616V19.1593H7.7389C8.19615 19.1593 8.61364 19.0326 8.99137 18.7791C9.38899 18.5257 9.59773 18.1456 9.61761 17.6388V17.668C9.61761 17.3756 9.55797 17.132 9.43869 16.937C9.33929 16.7421 9.20012 16.5861 9.0212 16.4692C8.84227 16.3327 8.64347 16.2353 8.42478 16.1768C8.20609 16.1183 7.97747 16.0891 7.7389 16.0891H5.05502C4.18027 16.0891 3.45463 15.9526 2.8781 15.6797C2.32144 15.4068 1.87413 15.0559 1.53616 14.6271C1.21807 14.1982 0.999381 13.7109 0.880097 13.1651C0.760814 12.6193 0.701172 12.0637 0.701172 11.4984C0.701172 10.9136 0.790634 10.3483 0.96956 9.80247C1.14848 9.25666 1.40693 8.77907 1.7449 8.36971C2.10275 7.94086 2.54013 7.60947 3.05702 7.37555C3.57392 7.14164 4.16039 7.02468 4.81645 7.02468H11.4963V10.0656Z"
      fill="#FF0F00"
    />
    <path
      d="M21.6841 7.17088C22.5589 7.17088 23.3342 7.30733 24.0101 7.58023C24.706 7.83365 25.2924 8.19427 25.7696 8.66211C26.2666 9.12995 26.6443 9.69526 26.9028 10.358C27.1612 11.0208 27.2904 11.7713 27.2904 12.6095V16.6154C27.2904 17.4341 27.1612 18.1846 26.9028 18.8669C26.6443 19.5296 26.2666 20.1047 25.7696 20.592C25.2924 21.0599 24.706 21.4205 24.0101 21.6739C23.3342 21.9273 22.5589 22.054 21.6841 22.054H20.7298C19.8551 22.054 19.0599 21.9176 18.3442 21.6447V27.1418H14.885V7.17088H21.6841ZM18.3442 10.5335V16.4984C18.3442 17.1417 18.543 17.668 18.9406 18.0774C19.3382 18.4867 19.8551 18.6914 20.4913 18.6914H21.6841C22.3203 18.6914 22.8471 18.4867 23.2646 18.0774C23.6821 17.668 23.8909 17.1417 23.8909 16.4984V12.6972C23.8909 12.054 23.6821 11.5374 23.2646 11.1475C22.8471 10.7382 22.3203 10.5335 21.6841 10.5335H18.3442Z"
      fill="#FF0F00"
    />
    <path
      d="M29.066 12.4633C29.066 11.7421 29.2051 11.0695 29.4834 10.4458C29.7618 9.80247 30.1296 9.23717 30.5868 8.74983C31.0441 8.2625 31.5709 7.88238 32.1673 7.60948C32.7637 7.31708 33.3602 7.17088 33.9566 7.17088H37.3562C38.1315 7.17088 38.8174 7.33657 39.4138 7.66796C40.0301 7.97985 40.5371 8.38921 40.9347 8.89603C41.3522 9.38337 41.6702 9.93893 41.8889 10.5627C42.1275 11.167 42.2468 11.7615 42.2468 12.3463V16.1183H32.5848V16.9078C32.5848 17.6095 32.7836 18.1066 33.1812 18.399C33.5788 18.6914 34.1057 18.8376 34.7617 18.8376H41.71V22.1125H33.9864C33.3105 22.1125 32.6743 21.9858 32.0779 21.7324C31.4814 21.4595 30.9546 21.0988 30.4974 20.6505C30.06 20.2022 29.7121 19.6661 29.4536 19.0423C29.1952 18.4185 29.066 17.7362 29.066 16.9955V12.4633ZM32.6146 13.3405H38.7876V13.1943C38.7876 12.4341 38.5887 11.8103 38.1911 11.323C37.7935 10.8161 37.2568 10.5627 36.5808 10.5627H34.7319C34.4138 10.5627 34.1256 10.6407 33.8671 10.7966C33.6087 10.9331 33.39 11.1183 33.2111 11.3522C33.0321 11.5861 32.883 11.8688 32.7637 12.2001C32.6643 12.512 32.6146 12.8434 32.6146 13.1943V13.3405Z"
      fill="#FF0F00"
    />
    <path
      d="M50.7117 18.6914C51.1889 18.6914 51.5964 18.5842 51.9344 18.3698C52.2723 18.1359 52.491 17.824 52.5904 17.4341H56.1391C55.9602 18.8376 55.4035 19.9585 54.4691 20.7967C53.5348 21.6349 52.2823 22.054 50.7117 22.054H49.4891C48.6342 22.054 47.8589 21.9273 47.163 21.6739C46.4672 21.4205 45.8807 21.0599 45.4036 20.592C44.9265 20.1047 44.5487 19.5296 44.2704 18.8669C44.012 18.1846 43.8827 17.4341 43.8827 16.6154V12.6095C43.8827 11.7713 44.012 11.0208 44.2704 10.358C44.5487 9.69526 44.9265 9.12995 45.4036 8.66211C45.8807 8.19427 46.4672 7.83365 47.163 7.58023C47.8589 7.30733 48.6342 7.17088 49.4891 7.17088H50.7117C52.322 7.17088 53.5944 7.58998 54.5288 8.42819C55.4632 9.26641 56 10.4165 56.1391 11.8785H52.6202C52.501 11.4497 52.2823 11.1183 51.9642 10.8844C51.6461 10.6504 51.2286 10.5335 50.7117 10.5335H49.4891C48.8529 10.5335 48.326 10.7382 47.9086 11.1475C47.5109 11.5374 47.3121 12.054 47.3121 12.6972V16.4984C47.3121 17.1417 47.5109 17.668 47.9086 18.0774C48.326 18.4867 48.8529 18.6914 49.4891 18.6914H50.7117Z"
      fill="#FF0F00"
    />
    <path
      d="M61.4822 22.054H57.9633V7.17088H61.4822V22.054ZM61.4822 5.56268H57.9633V2.1416H61.4822V5.56268Z"
      fill="#FF0F00"
    />
    <path
      d="M70.609 7.17088C71.4837 7.17088 72.2591 7.30733 72.935 7.58023C73.6308 7.83365 74.2173 8.19427 74.6945 8.66211C75.1915 9.12995 75.5692 9.69526 75.8277 10.358C76.0861 11.0208 76.2153 11.7713 76.2153 12.6095V16.6154C76.2153 17.4341 76.0861 18.1846 75.8277 18.8669C75.5692 19.5296 75.1915 20.1047 74.6945 20.592C74.2173 21.0599 73.6308 21.4205 72.935 21.6739C72.2591 21.9273 71.4837 22.054 70.609 22.054H69.6547C68.78 22.054 67.9848 21.9176 67.2691 21.6447V27.1418H63.8098V7.17088H70.609ZM67.2691 10.5335V16.4984C67.2691 17.1417 67.4679 17.668 67.8655 18.0774C68.2631 18.4867 68.78 18.6914 69.4162 18.6914H70.609C71.2452 18.6914 71.772 18.4867 72.1895 18.0774C72.607 17.668 72.8157 17.1417 72.8157 16.4984V12.6972C72.8157 12.054 72.607 11.5374 72.1895 11.1475C71.772 10.7382 71.2452 10.5335 70.609 10.5335H67.2691Z"
      fill="#FF0F00"
    />
    <path
      d="M84.1637 10.5042C83.6469 10.5042 83.2194 10.5725 82.8814 10.7089C82.5634 10.8259 82.3148 10.9916 82.1359 11.206C81.957 11.4204 81.8278 11.6836 81.7483 11.9955C81.6886 12.2879 81.6588 12.6095 81.6588 12.9604V22.054H78.3189V2.1416H81.6588V7.49252C81.957 7.39505 82.2652 7.31707 82.5832 7.25859C82.9212 7.20012 83.2791 7.17088 83.6568 7.17088H85.446C86.3208 7.17088 87.0862 7.31708 87.7423 7.60948C88.4182 7.90187 88.9748 8.29174 89.4122 8.77907C89.8695 9.24691 90.2074 9.79273 90.4261 10.4165C90.6448 11.0208 90.7542 11.6348 90.7542 12.2586V22.054H87.3844V12.9604C87.3844 12.6095 87.3546 12.2879 87.2949 11.9955C87.2353 11.6836 87.1061 11.4204 86.9073 11.206C86.7283 10.9916 86.4798 10.8259 86.1618 10.7089C85.8437 10.5725 85.4262 10.5042 84.9093 10.5042H84.1637Z"
      fill="#FF0F00"
    />
    <path
      d="M96.6033 22.054H93.0844V7.17088H96.6033V22.054ZM96.6033 5.56268H93.0844V2.1416H96.6033V5.56268Z"
      fill="#FF0F00"
    />
    <path
      d="M105.432 18.6914C105.909 18.6914 106.317 18.5842 106.655 18.3698C106.992 18.1359 107.211 17.824 107.311 17.4341H110.859C110.68 18.8376 110.124 19.9585 109.189 20.7967C108.255 21.6349 107.002 22.054 105.432 22.054H104.209C103.354 22.054 102.579 21.9273 101.883 21.6739C101.187 21.4205 100.601 21.0599 100.124 20.592C99.6466 20.1047 99.2689 19.5296 98.9905 18.8669C98.7321 18.1846 98.6029 17.4341 98.6029 16.6154V12.6095C98.6029 11.7713 98.7321 11.0208 98.9905 10.358C99.2689 9.69526 99.6466 9.12995 100.124 8.66211C100.601 8.19427 101.187 7.83365 101.883 7.58023C102.579 7.30733 103.354 7.17088 104.209 7.17088H105.432C107.042 7.17088 108.315 7.58998 109.249 8.42819C110.183 9.26641 110.72 10.4165 110.859 11.8785H107.34C107.221 11.4497 107.002 11.1183 106.684 10.8844C106.366 10.6504 105.949 10.5335 105.432 10.5335H104.209C103.573 10.5335 103.046 10.7382 102.629 11.1475C102.231 11.5374 102.032 12.054 102.032 12.6972V16.4984C102.032 17.1417 102.231 17.668 102.629 18.0774C103.046 18.4867 103.573 18.6914 104.209 18.6914H105.432Z"
      fill="#FF0F00"
    />
    <path
      d="M112.532 0.141846V0.810915H111.116V5.14188H110.243V0.810915H108.819V0.141846H112.532Z"
      fill="#4E4E4E"
    />
    <path
      d="M119.019 0.141846V5.14188H118.146V1.7174L116.524 5.14188H115.919L114.288 1.7174V5.14188H113.416V0.141846H114.357L116.225 4.06274L118.085 0.141846H119.019Z"
      fill="#4E4E4E"
    />
  </svg>
);

export default BrandLogoSvg;
