/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import AiAssistantAvatarSvg from '#assets/aiAssistantAvatar.asset';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import { formatDistanceToNow } from 'date-fns';
import {
  Stack,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import {
  StyledOrderedList,
  StyledUnorderedList,
  StyledListItem,
  StyledParagraph,
  ResponseAvatar,
  ErrorMessage,
  MessageTime,
  MessageText,
} from './responseMessage.styled';
import BlinkingText from '#components/blinkingText';

const ResponseMessage = ({
  progressStatus,
  response,
  isComplete,
  isError,
  messageTime,
}) => {
  return (
    <Stack direction={'row'} spacing={2}>
      <ResponseAvatar>
        <AiAssistantAvatarSvg width={32} height={32} />
      </ResponseAvatar>
      <Stack direction={'column'} justifyContent={'center'}>
        {messageTime && (
          <MessageTime component={'div'}>
            {formatDistanceToNow(messageTime)}
          </MessageTime>
        )}
        <MessageText component={'div'}>
          {isError ? (
            <ErrorMessage>{response}</ErrorMessage>
          ) : progressStatus && !isComplete ? (
            <BlinkingText>{progressStatus}</BlinkingText>
          ) : (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeKatex]}
              components={{
                table: (props) => (
                  <TableContainer>
                    <Table>{props.children}</Table>
                  </TableContainer>
                ),
                thead: TableHead,
                tbody: TableBody,
                tr: TableRow,
                th: TableCell,
                td: TableCell,
                ol: StyledOrderedList,
                ul: StyledUnorderedList,
                li: StyledListItem,
                p: StyledParagraph,
              }}
            >
              {response}
            </ReactMarkdown>
          )}

          {!isComplete && <BlinkingText>...</BlinkingText>}
        </MessageText>
      </Stack>
    </Stack>
  );
};

export default ResponseMessage;

ResponseMessage.propTypes = {
  progressStatus: PropTypes.string,
  response: PropTypes.string,
  messageTime: PropTypes.any,
  isComplete: PropTypes.bool,
  isError: PropTypes.bool,
  children: PropTypes.node,
};
