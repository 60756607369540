/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  Grid,
  Hidden,
  IconButton,
  Link,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import {
  IconBox,
  LogoGrid,
  ProfileGridWrap,
  StyledAppBar,
  StyledTypography,
} from './appbar.styled';
import * as path from '#constants/uiPaths.constant';
import BrandLogoSvg from '#assets/brandLogo.asset';
import { MenuOutlined } from '@mui/icons-material';
import { useUserContext } from '#contexts/userContext';
import UserAvatar from '../userAvatar/userAvatar.component';

const Appbar = React.forwardRef(({ onSidebarToggle }, ref) => {
  const userContext = useUserContext();
  const theme = useTheme();
  const isMdScreenOrSmaller = useMediaQuery(theme.breakpoints.down('md'));

  const renderAuthDetails = () => {
    return <div>{userContext.user?.name}</div>;
  };

  return (
    <StyledAppBar ref={ref} position="sticky">
      <Toolbar disableGutters>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Hidden mdUp>
            <Grid item>
              <IconButton onClick={onSidebarToggle} sx={{ marginBottom: 2 }}>
                <MenuOutlined />
              </IconButton>
            </Grid>
          </Hidden>
          <LogoGrid item>
            <Link to={path.HOME}>
              <BrandLogoSvg
                height="100%"
                style={{
                  maxWidth: isMdScreenOrSmaller ? '80%' : '100%',
                  marginTop: isMdScreenOrSmaller ? 2 : 0,
                }}
              />
            </Link>
          </LogoGrid>
          <ProfileGridWrap item>
            <Hidden mdDown>
              <StyledTypography variant="subtitle1">
                {renderAuthDetails()}
              </StyledTypography>
            </Hidden>
            <IconBox>
              {userContext.isAuthenticated ? <UserAvatar /> : null}
            </IconBox>
          </ProfileGridWrap>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
});

Appbar.propTypes = {
  onSidebarToggle: PropTypes.func,
};

Appbar.displayName = 'SpeciphicAppbar';

export default Appbar;
