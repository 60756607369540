/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React from 'react';
import { Card, CardContent, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  CenteredTypography,
  ErrorBox,
  SpacedAlert,
} from './errorFallback.page.styled';
import BrandLogoSvg from '#assets/brandLogo.asset';

const ErrorFallbackPage = (props) => {
  const { state } = useLocation();
  let { error } = state || {};
  if (!error) {
    error = props.error;
  }

  const { t } = useTranslation();
  return (
    <ErrorBox>
      <Card variant="outlined">
        <CardContent>
          <BrandLogoSvg
            width="100%"
            height="100%"
            style={{
              maxWidth: '40%',
            }}
          />
          <Divider />
          <CenteredTypography variant="h3">
            {error?.message ? error.message : t('pages.errorFallback.message')}
          </CenteredTypography>
          {error?.data && (
            <SpacedAlert severity="warning">
              {error?.data.message} Code: {error?.data.errorCode}
            </SpacedAlert>
          )}
        </CardContent>
      </Card>
    </ErrorBox>
  );
};

ErrorFallbackPage.propTypes = {
  error: PropTypes.object,
};

export default ErrorFallbackPage;
