/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import fontSizes from '#themes/fontSizes';
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
  styled,
} from '@mui/material';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: 80,
  zIndex: 100,
  [`& .MuiDrawer-paper`]: {
    width: 80,
    boxSizing: 'border-box',
  },
}));

export const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledListItemButton = styled(ListItemButton)(
  ({ theme, selected }) => ({
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover, &.Mui-selected, &:focus': {
      color: theme.palette.primary.main,
      '&.Mui-selected:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiTypography-root':
        {
          color: selected
            ? theme.palette.primary.main
            : theme.palette.primary.light,
        },
    },
    marginTop: theme.spacing(2),
  }),
);

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  justifyContent: 'center',
  marginBottom: theme.spacing(0.5),
}));

export const StyledListItemText = styled(Typography)({
  textAlign: 'center',
  fontSize: fontSizes.extraSmall,
});
