/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  CircularProgress,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import DataGridTable from '#components/datagridTable';
import { useUserContext } from '#contexts/userContext';
import { fileProcessingStateApi } from '#services/apis';
import { format, parseISO } from 'date-fns';
import Filters from '#components/fileProcessingStates';
import { Delete } from '@mui/icons-material';
import { fileCollectionApi } from '#services/apis';
import { dataSourceApi } from '#services/apis';
import ConfirmationDialog from '#components/confirmationDialog';
import { useToastContext } from '#contexts/providers/toast.provider';
import * as path from '#constants/uiPaths.constant';

const FileProcessingStatePage = () => {
  const { t } = useTranslation();
  const { user, hasPermission } = useUserContext();

  const [fileProcessingStates, setFileProcessingStates] = useState([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState({
    fileCollectionIds: [],
    dataSourceIds: [],
    lastStatuses: [],
    startScannedDate: null,
    endScannedDate: null,
    maxRecords: 200,
  });
  const [selectedFileCollections, setSelectedFileCollections] = useState([]);
  const [selectedDataSources, setSelectedDataSources] = useState([]);
  const [fileCollections, setFileCollections] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [selectedFileProcessingState, setSelectedFileProcessingState] =
    useState();
  const { pushToast } = useToastContext();

  const getFileProcessingStates = async (tenantId, filters = defaultFilter) => {
    try {
      setLoading(true);
      const response = await fileProcessingStateApi.getFileProcessingStates(
        tenantId,
        filters,
      );
      setFileProcessingStates(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getFileCollections = async (tenantId) => {
    try {
      setLoading(true);
      const response = await fileCollectionApi.getFileCollections(
        tenantId,
        false,
      );
      setFileCollections(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getDataSources = async (tenantId) => {
    try {
      setLoading(true);
      const response = await dataSourceApi.getTenantDataSources(tenantId);
      setDataSources(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.tenantId) {
      getFileProcessingStates(user?.tenantId);
      getFileCollections(user?.tenantId);
      getDataSources(user?.tenantId);
    }
  }, [user?.tenantId]);

  const handleOpenDeleteDialog = (row) => {
    setDeleteDialogOpen(true);
    setSelectedFileProcessingState(row);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteFileProcessingState = async () => {
    try {
      setLoading(true);
      await fileProcessingStateApi.deleteFileProcessingState(
        user?.tenantId,
        selectedFileProcessingState.fileCollectionId,
        selectedFileProcessingState.dataSourceId,
        selectedFileProcessingState.fileId,
      );
      pushToast({
        message: t('pages.fileProcessingStates.toasts.deleted'),
        severity: 'success',
      });
      getFileProcessingStates(user.tenantId);
    } catch (error) {
      setError(error);
    } finally {
      setDeleteDialogOpen(false);
      setLoading(false);
    }
  };

  const columns = [
    {
      field: 'fileCollectionId',
      headerName: t('pages.fileProcessingStates.columns.fileCollectionId'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'dataSourceId',
      headerName: t('pages.fileProcessingStates.columns.dataSourceId'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'fileId',
      headerName: t('pages.fileProcessingStates.columns.fileId'),
      maxWidth: 200,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'lastStatus',
      headerName: t('pages.fileProcessingStates.columns.lastStatus'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (props) => {
        let textColor = 'black';
        switch (props.row.lastStatus) {
          case 'SCANNED':
            textColor = 'green';
            break;
          case 'PROCESSED':
            textColor = 'RoyalBlue';
            break;
          case 'FAILED':
            textColor = 'red';
            break;
          default:
            textColor = 'black';
        }

        return (
          <div style={{ color: textColor }}>
            {props.row.lastStatus.toUpperCase()}
          </div>
        );
      },
    },
    {
      field: 'lastScannedTime',
      headerName: t('pages.fileProcessingStates.columns.lastScannedTime'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.lastScannedTime
              ? format(
                  parseISO(props.row.lastScannedTime),
                  'do MMM yyyy h:m:s a',
                )
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'lastSuccessTime',
      headerName: t('pages.fileProcessingStates.columns.lastSuccessTime'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.lastSuccessTime
              ? format(
                  parseISO(props.row.lastSuccessTime),
                  'do MMM yyyy h:m:s a',
                )
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'lastFailedTime',
      headerName: t('pages.fileProcessingStates.columns.lastFailedTime'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: (props) => {
        return (
          <Typography>
            {props.row.lastFailedTime
              ? format(
                  parseISO(props.row.lastFailedTime),
                  'do MMM yyyy h:m:s a',
                )
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'noOfTries',
      headerName: t('pages.fileProcessingStates.columns.noOfTries'),
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: t('pages.fileCollections.columns.actions'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (props) => {
        return (
          <Box display="flex">
            {hasPermission(
              'qna:configure:fileCollection:dataSource:deleteFileProcessingRecord',
            ) && (
              <Tooltip title="Delete" arrow>
                <IconButton
                  onClick={() => handleOpenDeleteDialog(props.row)}
                  color="error"
                  disableRipple
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];

  const onFilterChange = (
    filters,
    selectedFileCollections,
    selectedDataSources,
  ) => {
    const selectedFileCollectionIDs = selectedFileCollections.map((name) => {
      const fileCollection = fileCollections.find(
        (collection) => collection.name === name,
      );
      return fileCollection.id;
    });
    const selectedDataSourceIDs = selectedDataSources.map((name) => {
      const dataSource = dataSources.find((ds) => ds.name === name);
      return dataSource.id;
    });
    filters.fileCollectionIds = selectedFileCollectionIDs;
    filters.dataSourceIds = selectedDataSourceIDs;
    getFileProcessingStates(user?.tenantId, filters);
    setDefaultFilter(filters);
    setSelectedFileCollections(selectedFileCollections);
    setSelectedDataSources(selectedDataSources);
  };

  function getRowId(row) {
    return row.fileId;
  }

  if (!hasPermission('system:audits')) {
    return <Navigate to={path.UNAUTHORIZED} />;
  }

  return (
    <Box>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: '80vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          style={{ maxWidth: '95vw' }}
        >
          <Grid item xs={12} sm={12}>
            <Filters
              onFilterChange={onFilterChange}
              fileCollections={fileCollections}
              dataSources={dataSources}
              defaultFilters={defaultFilter}
              defaultDataSources={selectedDataSources}
              defaultFileCollections={selectedFileCollections}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <DataGridTable
              rows={fileProcessingStates || null}
              columns={columns}
              rowHeight={80}
              getRowId={getRowId}
              error={error}
              header={t('pages.fileProcessingStates.header')}
            ></DataGridTable>
          </Grid>
        </Grid>
      )}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title={t('pages.fileProcessingStates.actions.delete.title')}
        messageLine1={t(
          'pages.fileProcessingStates.actions.delete.messageLine1',
          {
            itemName: selectedFileProcessingState?.fileId,
          },
        )}
        messageLine2={t(
          'pages.fileProcessingStates.actions.delete.messageLine2',
        )}
        onYesClick={handleDeleteFileProcessingState}
        onNoClick={handleCloseDeleteDialog}
      />
    </Box>
  );
};

FileProcessingStatePage.propTypes = { row: PropTypes.object };

export default FileProcessingStatePage;
