/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  TextField as MuiTextField,
} from '@mui/material';

const TextField = ({
  label,
  value,
  type,
  multiline,
  required,
  disabled,
  error,
  onChange,
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl variant="standard" fullWidth sx={{ marginTop: 0 }}>
      <FormLabel required={required}>{label}</FormLabel>
      <MuiTextField
        value={value}
        label=""
        fullWidth
        margin="dense"
        required={required}
        multiline={multiline}
        size="small"
        error={error !== undefined}
        helperText={error}
        disabled={disabled}
        onChange={handleChange}
        sx={{ marginTop: 0 }}
        type={type}
      />
    </FormControl>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func,
};

export default TextField;
