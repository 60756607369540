/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmationDialog = ({
  open,
  title,
  messageLine1 = '',
  messageLine2 = '',
  yesButtonLabel,
  noButtonLabel,
  onYesClick,
  onNoClick,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onNoClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText component={'div'}>
          <Typography component={'div'} gutterBottom>
            {messageLine1}
          </Typography>
          {messageLine2 && (
            <Typography component={'div'} gutterBottom>
              {messageLine2}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNoClick}>
          {noButtonLabel || t('components.confirmationDialog.actions.no')}
        </Button>
        <Button color="error" onClick={onYesClick} autoFocus>
          {yesButtonLabel || t('components.confirmationDialog.actions.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  messageLine1: PropTypes.string,
  messageLine2: PropTypes.string,
  yesButtonLabel: PropTypes.string,
  noButtonLabel: PropTypes.string,
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
};

export default ConfirmationDialog;
