/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import {
  Grid,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormLabel,
  Typography,
  CardHeader,
  ButtonGroup,
  Button,
  Alert,
  Divider,
  CardActions,
} from '@mui/material';
import { useUserContext } from '#contexts/userContext';
import { tenantApi } from '#services/apis';
import TextField from '#components/textField';
import ProcessingParameters from '#components/processingParameters';
import SearchParameters from '#components/searchParameters';
import DataStoreParameters from './dataStoreParameters.component';
import DocumentStoreParameters from './documentStoreParameters.component';
import LlmParameters from './llmParameters.component';
import ConversationMessageParameters from './conversationMessageParameters.component';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '#contexts/providers/toast.provider';
import { CONFIGURE } from '#constants/uiPaths.constant';
import ChatEncryptionParameters from './chatEncryptionParameters.component';
import * as path from '#constants/uiPaths.constant';

const Tenant = () => {
  const { user, hasPermission } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState('');
  const [tenant, setTenant] = useState();
  const [prevValues, setPrevValues] = useState({});
  const [nameError, setNameError] = useState();
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const getTenant = async () => {
      try {
        setLoading(true);
        const tenant = await tenantApi.getTenant(user?.tenantId, true);
        setTenant(tenant);
        setPrevValues(tenant);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.tenantId) {
      getTenant();
    }
  }, [user?.tenantId]);

  useEffect(() => {
    setEditable(hasPermission('system:configure:tenant'));
  }, [user]);

  const onNameChange = (value) => {
    setTenant((tenant) => {
      return { ...tenant, name: value };
    });
    setHasChanged(true);
  };

  const onDescriptionChange = (value) => {
    setTenant((tenant) => {
      return { ...tenant, description: value };
    });
    setHasChanged(true);
  };

  const onTermsAndConditionsChange = (value) => {
    setTenant((tenant) => {
      return { ...tenant, termsAndConditions: value };
    });
    setHasChanged(true);
  };

  const handleCloseClick = () => {
    navigate(CONFIGURE);
  };

  const handleCancelClick = () => {
    if (tenant.id) {
      setTenant(prevValues);
    } else {
      navigate(CONFIGURE);
    }
    setHasChanged(false);
  };

  const handleSaveClick = async () => {
    setError();
    setNameError();
    if (!tenant.name) {
      setNameError(t('components.tenant.errors.nameRequired'));
      return;
    }
    const { id, name, description } = tenant;
    try {
      const t = await tenantApi.saveTenant(user?.tenantId, {
        id,
        name,
        description,
      });
      pushToast({
        message: t('components.tenant.toasts.saved'),
        severity: 'success',
      });
      setTenant(t);
      setPrevValues({ ...t });
      setHasChanged(false);
    } catch (error) {
      setError(error);
    }
  };

  if (loading || !tenant) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '80vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!hasPermission('system:tenant')) {
    return <Navigate to={path.UNAUTHORIZED} />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title={t('components.tenant.header')}
            subheader={error && <Alert severity="error">{error.message}</Alert>}
            action={
              <ButtonGroup>
                <Button onClick={handleCloseClick} size="small" color="primary">
                  {t('components.tenant.actions.close')}
                </Button>
              </ButtonGroup>
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              {tenant.id && (
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" fullWidth>
                    <FormLabel>{t('components.tenant.fields.id')}</FormLabel>
                    <Typography>{tenant.id}</Typography>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <TextField
                  label={t('components.tenant.fields.name')}
                  required
                  onChange={onNameChange}
                  value={tenant.name}
                  disabled={!editable}
                  error={nameError}
                />
              </Grid>
              {tenant.createdDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.tenant.fields.createdAt', {
                      time: format(
                        parseISO(tenant?.createdDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.tenant.fields.createdBy', {
                      name: tenant.createdBy,
                    })}
                  </Typography>
                </Grid>
              )}
              {tenant.lastUpdatedDate && (
                <Grid
                  item
                  xs={12}
                  md={3}
                  container
                  justifyContent={'flex-end'}
                  alignContent={'center'}
                >
                  <Typography>
                    {t('components.tenant.fields.lastUpdatedAt', {
                      time: format(
                        parseISO(tenant?.lastUpdatedDate),
                        'do MMM yyyy h:mm:ss a',
                      ),
                    })}
                    <br />
                    {t('components.tenant.fields.lastUpdatedBy', {
                      name: tenant.lastUpdatedBy,
                    })}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  label={t('components.tenant.fields.description')}
                  value={tenant.description || ''}
                  multiline
                  onChange={onDescriptionChange}
                  disabled={!editable}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t('components.tenant.fields.termsAndConditions')}
                value={tenant.termsAndConditions || ''}
                multiline
                onChange={onTermsAndConditionsChange}
                disabled={!editable}
              />
            </Grid>
          </CardContent>
          {editable && hasChanged && (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleCancelClick}>
                {t('components.tenant.actions.cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSaveClick}
              >
                {t('components.tenant.actions.save')}
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
      {tenant?.id && (
        <Grid item xs={12}>
          <DataStoreParameters tenant={tenant} editable={false} />
        </Grid>
      )}
      {tenant?.id && (
        <Grid item xs={12}>
          <DocumentStoreParameters tenant={tenant} editable={false} />
        </Grid>
      )}
      {tenant?.id && (
        <Grid item xs={12}>
          <LlmParameters
            tenantId={tenant?.id}
            editable={editable}
            defaultValues={{
              useDefaultOpenAiKey: tenant?.useDefaultOpenAiKey,
              useDefaultAzureOpenAiKey: tenant?.useDefaultAzureOpenAiKey,
              openAiApiKey: tenant?.openAiApiKey,
              azureOpenAiApiEndpoint: tenant?.azureOpenAiApiEndpoint,
              azureOpenAiApiKey: tenant?.azureOpenAiApiKey,
            }}
          />
        </Grid>
      )}
      {tenant?.id && (
        <Grid item xs={12}>
          <ProcessingParameters
            tenantId={user.tenantId}
            editable={editable}
            defaultValues={tenant.processingParameters}
          />
        </Grid>
      )}
      {tenant?.id && (
        <Grid item xs={12}>
          <SearchParameters
            tenantId={user.tenantId}
            editable={editable}
            defaultValues={tenant.searchParameters}
          />
        </Grid>
      )}
      {tenant?.id && (
        <Grid item xs={12}>
          <ConversationMessageParameters
            tenant={tenant}
            editable={editable}
            defaultValues={{
              conversationMessageDeleteSoft:
                tenant.conversationMessageDeleteSoft,
              conversationMessagePurgeDays: tenant.conversationMessagePurgeDays,
              maxMessageReferenceDocuments: tenant.maxMessageReferenceDocuments,
            }}
          />
        </Grid>
      )}
      {tenant?.id && (
        <Grid item xs={12}>
          <ChatEncryptionParameters
            tenant={tenant}
            editable={editable}
            defaultValues={{
              encryptUserMessages: tenant.encryptUserMessages,
              keyManagerImplementation: tenant.keyManagerImplementation,
              userRoleResolverImplementation:
                tenant.userRoleResolverImplementation,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Tenant;
