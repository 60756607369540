/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Typography, styled } from '@mui/material';

export const TermsAndConditions = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '9px',
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.down('xl')]: {
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
  },
}));
