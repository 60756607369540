/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Typography, styled } from '@mui/material';

export const ContextInfo = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  textAlign: 'left',
  fontStyle: 'italic',
  color: theme.palette.info.main,
}));
