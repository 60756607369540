/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import axios from 'axios';
import {
  CORE_API_BASE_URL,
  INGESTION_API_BASE_URL,
  USER_AUTH_API_URL,
} from '#config/api.config';
import { getUsersAccessToken } from '#services/user.service';

const coreApiHttpClient = axios.create({
  baseURL: CORE_API_BASE_URL,
});

const ingestionApiHttpClient = axios.create({
  baseURL: INGESTION_API_BASE_URL,
});

const userAuthApiHttpClient = axios.create({
  baseURL: USER_AUTH_API_URL,
});

coreApiHttpClient.interceptors.request.use((config) => {
  const accessToken = getUsersAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

coreApiHttpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { code, message, response = {} } = error;
    const { data, status } = response;
    return Promise.reject({
      code,
      message,
      data,
      status,
    });
  },
);

ingestionApiHttpClient.interceptors.request.use((config) => {
  const accessToken = getUsersAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

ingestionApiHttpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { code, message, response = {} } = error;
    const { data, status } = response;
    return Promise.reject({
      code,
      message,
      data,
      status,
    });
  },
);

userAuthApiHttpClient.interceptors.request.use((config) => {
  const accessToken = getUsersAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
});

userAuthApiHttpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { code, message, response = {} } = error;
    const { data, status } = response;
    return Promise.reject({
      code,
      message,
      data,
      status,
    });
  },
);

export { coreApiHttpClient, ingestionApiHttpClient, userAuthApiHttpClient };
