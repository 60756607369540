/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

const fontSizes = {
  extraLarge: '18px',
  large: '16px',
  medium: '15px',
  regular: '14px',
  small: '11px',
  extraSmall: '10px',
};

export default fontSizes;
