/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  CircularProgress,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import DataGridTable from '#components/datagridTable';
import { useUserContext } from '#contexts/userContext';
import * as path from '#constants/uiPaths.constant';
import ConfirmationDialog from '#components/confirmationDialog';
import { useToastContext } from '#contexts/providers/toast.provider';
import { dataSourceApi } from '#services/apis';

const DataSources = ({ fileCollectionId }) => {
  const { user, hasPermission } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const getDataSources = async () => {
    try {
      setLoading(true);
      const response = await dataSourceApi.getDataSources(
        user.tenantId,
        fileCollectionId,
        false,
      );
      setDataSources(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fileCollectionId) {
      getDataSources();
    }
  }, [fileCollectionId]);

  const handleOpenDeleteDialog = (row) => {
    setDeleteDialogOpen(true);
    setSelectedDataSource(row);
  };

  const handleDeleteDataSource = async () => {
    try {
      setLoading(true);
      await dataSourceApi.deleteDataSource(
        user.tenantId,
        fileCollectionId,
        selectedDataSource.id,
      );
      pushToast({
        message: t('components.dataSource.toasts.deleted'),
        severity: 'success',
      });
      getDataSources();
      setSelectedDataSource();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleNewDataSource = () => {
    navigate(
      `${path.FILE_COLLECTIONS}/${fileCollectionId}/dataSources/create-new`,
    );
  };

  const handleEditDataSource = (row) => {
    navigate(
      `${path.FILE_COLLECTIONS}/${fileCollectionId}/dataSources/${row.id}`,
    );
  };

  const actionColumn = (props) => {
    return (
      <Box display="flex">
        {hasPermission('qna:configure:fileCollection:dataSource:update') && (
          <Tooltip title="Edit" arrow>
            <IconButton
              size="small"
              onClick={() => handleEditDataSource(props.row)}
              disableRipple
            >
              <Edit fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {hasPermission('qna:configure:fileCollection:dataSource:delete') && (
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => handleOpenDeleteDialog(props.row)}
              color="error"
              disableRipple
            >
              <Delete fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: t('components.dataSources.columns.name'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'description',
      headerName: t('components.dataSources.columns.description'),
      minWidth: 200,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'endpointType',
      headerName: t('components.dataSources.columns.endpointType'),
      minWidth: 100,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'active',
      headerName: t('components.dataSources.columns.active'),
      minWidth: 50,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdBy',
      headerName: t('pages.fileCollections.columns.createdBy'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'createdDate',
      headerName: t('components.dataSources.columns.createdDate'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'lastUpdatedBy',
      headerName: t('pages.fileCollections.columns.lastUpdatedBy'),
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'lastUpdatedDate',
      headerName: t('components.dataSources.columns.lastUpdatedDate'),
      headerAlign: 'left',
      width: 150,
      align: 'left',
    },
    {
      field: 'actions',
      headerName: t('components.dataSources.columns.actions'),
      headerAlign: 'center',
      width: 120,
      align: 'center',
      sortable: false,
      renderCell: actionColumn,
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container sx={{ width: '100%' }}>
          <DataGridTable
            rows={dataSources}
            columns={columns}
            header={t('components.dataSources.header')}
            onRowDoubleClick={handleEditDataSource}
            allowNewRecord={hasPermission(
              'qna:configure:fileCollection:dataSource:create',
            )}
            onNewRecord={handleNewDataSource}
            error={error}
          ></DataGridTable>
        </Grid>
      )}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title={t('components.dataSources.actions.delete.title')}
        messageLine1={t('components.dataSources.actions.delete.messageLine1', {
          itemName: selectedDataSource?.name,
        })}
        messageLine2={t('components.dataSources.actions.delete.messageLine2')}
        onYesClick={handleDeleteDataSource}
        onNoClick={handleCloseDeleteDialog}
      />
    </Box>
  );
};

DataSources.propTypes = {
  row: PropTypes.any,
  fileCollectionId: PropTypes.string,
};

export default DataSources;
