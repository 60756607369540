/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { coreApiHttpClient } from './httpClients.';

const roleAndPermissionApi = {
  getRoles: async (tenantId, includeChildRecords = false) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/roles?includeChildRecords=${includeChildRecords}`,
    );
    const { data } = response;
    return data;
  },

  getRole: async (tenantId, roleId, includeChildRecords = false) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/roles/${roleId}?includeChildRecords=${includeChildRecords}`,
    );
    const { data } = response;
    return data;
  },

  saveRole: async (tenantId, role) => {
    let response;
    if (role.id) {
      response = await coreApiHttpClient.put(
        `/api/tenants/${tenantId}/roles/${role.id}`,
        role,
      );
    } else {
      response = await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/roles`,
        role,
      );
    }

    const { data } = response;
    return data;
  },

  deleteRole: async (tenantId, roleId) => {
    await coreApiHttpClient.delete(`/api/tenants/${tenantId}/roles/${roleId}`);
  },

  saveRolePermissions: async (tenantId, roleId, permissions) => {
    const response = await coreApiHttpClient.put(
      `/api/tenants/${tenantId}/roles/${roleId}/permissions`,
      { roleId, permissions },
    );
    const { data } = response;
    return data;
  },

  saveRoleFileCollections: async (
    tenantId,
    roleId,
    restrictFileCollections,
    fileCollections,
  ) => {
    const response = await coreApiHttpClient.put(
      `/api/tenants/${tenantId}/roles/${roleId}/fileCollections`,
      { roleId, restrictFileCollections, fileCollections },
    );
    const { data } = response;
    return data;
  },

  getPermissions: async (tenantId) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/permissions`,
    );
    const { data } = response;
    return data;
  },

  getUserPermissions: async (tenantId) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/user/permissions`,
    );
    const { data } = response;
    return data;
  },
};

export default roleAndPermissionApi;
