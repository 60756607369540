/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

const muiButton = {
  styleOverrides: {
    root: {
      borderRadius: '0',
    },
    containedSizeLarge: {
      padding: '6px 36px',
    },
    outlinedSizeLarge: ({ theme }) => ({
      padding: '6px 12px',
      color: theme.palette.text.controls,
    }),
    outlinedSizeSmall: ({ theme }) => ({
      padding: '0px 16px',
      color: theme.palette.text.controls,
    }),
    sizeSmall: ({ theme }) => ({
      fontSize: '1rem',
      lineHeight: theme.typography.pxToRem(21),
      minHeight: '29px',
    }),
    sizeMedium: ({ theme }) => ({
      fontSize: '1rem',
      lineHeight: theme.typography.pxToRem(21),
      minHeight: '33px',
    }),
    sizeLarge: ({ theme }) => ({
      fontSize: '1rem',
      lineHeight: theme.typography.pxToRem(21),
      minHeight: '38px',
    }),
    endIcon: ({ theme }) => ({
      '& > :nth-of-type(1)': {
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.primary.main,
      },
    }),
  },
};

export default muiButton;
