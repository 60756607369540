/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { coreApiHttpClient } from './httpClients.';

const tenantApi = {
  getTenant: async (tenantId, includeChildRecords = false) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}?includeChildRecords=${includeChildRecords}`,
    );
    const { data } = response;
    return data;
  },

  saveTenant: async (tenant) => {
    const response = await coreApiHttpClient.post(
      `/api/tenants/${tenant.id}`,
      tenant,
    );
    const { data } = response;
    return data;
  },
};

export default tenantApi;
