/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useUserContext } from '#contexts/userContext';
import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
} from '@mui/material';
import { CloudUpload, KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import SelectBox from '#components/selectBox';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const QnAContext = ({ qnaContext = {}, fileCollections = [], onChange }) => {
  const { user, hasPermission } = useUserContext();

  const { t } = useTranslation();

  const searchContextMenuAnchor = useRef();

  const [searchContextMenuOpen, setSearchContextMenuOpen] = useState(false);

  const [searchContexts, setSearchContexts] = useState([]);
  const [fileCollectionOptions, setFileCollectionOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    const searchContexts = [];
    if (hasPermission('qna:context:anything')) {
      searchContexts.push({
        value: 'aboutAnything',
        label: t('components.qnaContext.searchContextOptions.aboutAnything'),
      });
    }
    if (hasPermission('qna:context:fileCollection')) {
      searchContexts.push({
        value: 'aboutFileCollection',
        label: t(
          'components.qnaContext.searchContextOptions.aboutFileCollection',
        ),
      });
    }
    if (hasPermission('qna:context:file')) {
      searchContexts.push({
        value: 'aboutMyFile',
        label: t('components.qnaContext.searchContextOptions.aboutMyFiles'),
        disabled: true,
      });
    }
    setSearchContexts(searchContexts);
  }, [user]);

  useEffect(() => {
    setFileCollectionOptions(
      fileCollections.map((fc) => {
        return { value: fc.id, label: fc.name };
      }),
    );
  }, [fileCollections]);

  useEffect(() => {
    setSelectedOption(
      searchContexts.find((o) => o.value === qnaContext.searchContext),
    );
  }, [qnaContext.searchContext, searchContexts]);

  const showSearchContextMenu = () => {
    setSearchContextMenuOpen(true);
  };

  const onSearchContexMenuOptionClick = async (mo) => {
    const fileCollectionId =
      mo.searchContext === 'aboutAnything' ? '' : mo.fileCollectionId;
    const fileCollectionName =
      mo.searchContext === 'aboutAnything' ? '' : mo.fileCollectionName;

    await onChange({
      ...qnaContext,
      searchContext: mo.value,
      fileCollectionId,
      fileCollectionName,
    });
    setSelectedOption(mo);
    setSearchContextMenuOpen(false);
  };

  const onFileCollectionChange = async (value) => {
    const fileCollection = fileCollectionOptions.find((o) => o.value === value);
    await onChange({
      ...qnaContext,
      fileCollectionId: value,
      fileCollectionName: fileCollection?.label,
      fileId: '',
    });
  };

  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      spacing={1}
      justifyContent="flex-start"
    >
      {hasPermission('qna:context') && (
        <Chip
          label={selectedOption?.label}
          color="primary"
          variant="outlined"
          onDelete={showSearchContextMenu}
          onClick={showSearchContextMenu}
          deleteIcon={<KeyboardArrowDown />}
          ref={searchContextMenuAnchor}
        />
      )}
      {hasPermission('qna:context') && (
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={1}
          justifyContent="space-between"
          sx={{ flexGrow: 1 }}
        >
          {qnaContext?.searchContext === 'aboutAnything' && <Box />}
          {qnaContext?.searchContext === 'aboutFileCollection' && (
            <SelectBox
              options={fileCollectionOptions || []}
              value={qnaContext.fileCollectionId || ''}
              onChange={onFileCollectionChange}
            />
          )}
          {qnaContext?.searchContext === 'aboutMyFile' && (
            <SelectBox options={[]} />
          )}
          {qnaContext?.searchContext === 'aboutMyFile' && (
            <IconButton
              size="small"
              component="label"
              role={undefined}
              tabIndex={-1}
              sx={{ width: '30px', height: '30px' }}
            >
              <CloudUpload color="primary" />
              <VisuallyHiddenInput type="file" />
            </IconButton>
          )}
        </Stack>
      )}
      <Menu
        anchorEl={searchContextMenuAnchor?.current}
        open={searchContextMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setSearchContextMenuOpen(false)}
      >
        {searchContexts.map((mo) => {
          return (
            !mo.hidden && (
              <MenuItem
                key={mo.value}
                onClick={() => onSearchContexMenuOptionClick(mo)}
                disabled={mo.disabled}
              >
                {mo.label}
              </MenuItem>
            )
          );
        })}
      </Menu>
    </Stack>
  );
};

QnAContext.propTypes = {
  qnaContext: PropTypes.object,
  fileCollections: PropTypes.array,
  onChange: PropTypes.func,
};

export default QnAContext;
