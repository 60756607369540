/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { styled, IconButton } from '@mui/material';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0),
  color: theme.palette.primary.main,
}));
