const DefaultValues = {
  defaultLlm: '',
  openAiCompletionModel: 'gpt-3.5-turbo',
  azureOpenAiCompletionDeploymentName: '',
  azureOpenAiCompletionModel: 'gpt-3.5-turbo',
  queryEmbeddingStrategy: 'NONE',
  openAiEmbeddingModel: 'text-embedding-ada-002',
  azureOpenAiEmbeddingDeploymentName: '',
  azureOpenAiEmbeddingModel: 'text-embedding-ada-002',
  chatCompletionSystemPrompt: 'You are a helpful assistant.',
  chatCompletionUserPromptTemplate: '',
  chatCompletionUserCondenseHistoryPromptTemplate: '',
  chatCompletionContextIncludeHistory: false,
  chatCompletionContextCondenseHistory: false,
  chatCompletionContextMaxHistory: 10,
  chatCompletionContextLongMessageStrategy: 'TRUNCATE_PREFER_HISTORY',
  chatCompletionMaxTokens: 1000,
  chatCompletionNumberOfCompletions: 1,
  chatCompletionFrequencyPenalty: 0.1,
  chatCompletionPresencePenalty: 0.1,
  chatCompletionTemperature: 0.5,
  chatCompletionTopP: 0.5,
  condensePromptContextSystemPrompt: '',
  documentRetrievalStrategy: 'KEYWORD_BM25',
  documentRetrievalMaxSize: 5,
  documentRerankingStrategy: 'TRANSFORMER',
  documentRerankingModel: 'cross-encoder/ms-marco-MiniLM-L-12-v2',
  transformerEmbeddingModel: 'BAAI/bge-large-zh-v1.5',
};

export default DefaultValues;
