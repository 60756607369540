/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Grid,
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import BooleanField from '#components/booleanField';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useToastContext } from '#contexts/providers/toast.provider';
import defaults from './defaultValues';
import NumberField from '#components/numberField';
import { parametersApi } from '#services/apis';

const ConversationMessageParameters = ({ tenant, editable, defaultValues }) => {
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [open, setOpen] = useState(true);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conversationMessageParameters, setConversationMessageParameters] =
    useState(
      defaultValues || {
        conversationMessageDeleteSoft: defaults.conversationMessageDeleteSoft,
        conversationMessagePurgeDays: defaults.conversationMessagePurgeDays,
        maxMessageReferenceDocuments: defaults.maxMessageReferenceDocuments,
      },
    );
  const [prevValues, setPrevValues] = useState(
    defaultValues || {
      conversationMessageDeleteSoft: defaults.conversationMessageDeleteSoft,
      conversationMessagePurgeDays: defaults.conversationMessagePurgeDays,
      maxMessageReferenceDocuments: defaults.maxMessageReferenceDocuments,
    },
  );

  const handleChange = (fieldName, value) => {
    setConversationMessageParameters({
      ...conversationMessageParameters,
      [fieldName]: value,
    });
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    setErrors({});
    setConversationMessageParameters({ ...prevValues });
    setHasChanged(false);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasError = false;
    setError();
    if (!hasError) {
      setLoading(true);
      setErrors({});
      try {
        await parametersApi.saveConversationMessageParameters(
          tenant.id,
          conversationMessageParameters,
        );
        pushToast({
          message: t('components.conversationMessageParameters.toasts.saved'),
          severity: 'success',
        });
        setPrevValues({ ...conversationMessageParameters });
        setHasChanged(false);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t('components.conversationMessageParameters.header')}
        subheader={error && <Alert severity="error">{error.message}</Alert>}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.conversationMessageParameters.fields.conversationMessageDeleteSoft',
                )}
                onChange={(value) =>
                  handleChange('conversationMessageDeleteSoft', value)
                }
                value={
                  conversationMessageParameters?.conversationMessageDeleteSoft
                }
                disabled={editable}
                error={errors['conversationMessageDeleteSoft']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.conversationMessageParameters.fields.conversationMessagePurgeDays',
                )}
                value={
                  conversationMessageParameters.conversationMessagePurgeDays
                }
                min={1}
                step={1}
                onChange={(value) =>
                  handleChange('conversationMessagePurgeDays', value)
                }
                disabled={editable}
                error={errors['conversationMessagePurgeDays']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.conversationMessageParameters.fields.maxMessageReferenceDocuments',
                )}
                value={
                  conversationMessageParameters.maxMessageReferenceDocuments
                }
                min={1}
                step={1}
                onChange={(value) =>
                  handleChange('maxMessageReferenceDocuments', value)
                }
                disabled={editable}
                error={errors['maxMessageReferenceDocuments ']}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {loading ? (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <CircularProgress />
          </CardActions>
        ) : (
          hasChanged && (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleCancelClick}>
                {t('components.conversationMessageParameters.actions.cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSaveClick}
              >
                {t('components.conversationMessageParameters.actions.save')}
              </Button>
            </CardActions>
          )
        )}
      </Collapse>
    </Card>
  );
};

ConversationMessageParameters.propTypes = {
  tenant: PropTypes.object,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  defaultValues: PropTypes.object,
};

export default ConversationMessageParameters;
