/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import TextField from '#components/textField';

const SelectUsers = ({ open, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);

  const selectUser = () => {
    if (!selectedUsers.includes(user)) {
      setSelectedUsers([...selectedUsers, user]);
    }
    setUser('');
  };

  const deleteSelected = (user) => {
    const idx = selectedUsers.indexOf(user);
    if (idx > -1) {
      selectedUsers.splice(idx, 1);
      setSelectedUsers([...selectedUsers]);
    }
  };

  const handleSubmit = () => {
    onSubmit(selectedUsers);
    setSelectedUsers([]);
  };

  const handleCancel = () => {
    onCancel();
    setSelectedUsers([]);
  };

  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              label={t('components.selectUsers.fields.selectUser')}
              value={user}
              required
              onChange={setUser}
            />
          </Grid>
          <Grid item xs container alignItems={'center'}>
            <Button
              variant="contained"
              color="primary"
              onClick={selectUser}
              sx={{ marginTop: '12px' }}
            >
              {t('components.selectUsers.actions.add')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={1} flexWrap="wrap" useFlexGap>
              {selectedUsers.map((user, idx) => (
                <Chip
                  key={idx}
                  label={user}
                  color="primary"
                  onDelete={() => deleteSelected(user)}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" variant="contained">
          {t('components.selectUsers.actions.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={selectedUsers.length === 0}
          variant="contained"
        >
          {t('components.selectUsers.actions.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SelectUsers.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default SelectUsers;
