/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { ingestionApiHttpClient } from './httpClients.';

const fileProcessingStateApi = {
  getFileProcessingStates: async (tenantId, filters) => {
    const response = await ingestionApiHttpClient.post(
      `/api/tenants/${tenantId}/fileProcessingState/search`,
      filters,
    );
    const { data } = response;
    return data;
  },

  deleteFileProcessingState: async (
    tenantId,
    fileCollectionId,
    dataSourceId,
    fileId,
  ) => {
    await ingestionApiHttpClient.delete(
      `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/dataSources/${dataSourceId}?fileId=${fileId}`,
    );
  },
};

export default fileProcessingStateApi;
