/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  StyledDrawer,
  StyledList,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from './appSideNavbar.styled';
import EnterpriseIconSvg from '#assets/enterpriseIcon.asset';
import ConfigureIconSvg from '#assets/configureIcon.asset';
import * as PATHS from '#constants/uiPaths.constant';
import { useUserContext } from '#contexts/userContext';

const AppSideNavbar = ({ isVisible }) => {
  const { user, hasPermission, hasAnyPermission } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [navigationItems, setNavigationItems] = useState([]);

  const location = useLocation();
  const handleSidebarNavigation = (path, index) => {
    navigate(path);
    setSelectedIndex(index);
  };
  useEffect(() => {
    const navList = [];
    if (hasPermission('qna')) {
      navList.push({
        labelKey: 'components.sidebarNavigation.items.qna',
        icon: <EnterpriseIconSvg />,
        path: PATHS.QNA,
      });
    }
    if (hasAnyPermission(['qna:configure', 'coa:configure', 'system'])) {
      navList.push({
        labelKey: 'components.sidebarNavigation.items.configure',
        icon: <ConfigureIconSvg />,
        path: PATHS.CONFIGURE,
      });
    }
    setNavigationItems(navList);
  }, [user]);

  useEffect(() => {
    const index = navigationItems.findIndex((item) => {
      const includes = location.pathname.includes(item.path, 1);
      return includes;
    });
    if (index !== -1) {
      setSelectedIndex(index);
    }
  }, [location.pathname]);

  return (
    <>
      {isVisible && (
        <StyledDrawer variant="permanent">
          <Toolbar />
          <StyledList>
            {navigationItems.map((item, index) => (
              <StyledListItemButton
                key={index}
                selected={selectedIndex === index}
                onClick={() => {
                  handleSidebarNavigation(item.path, index);
                }}
              >
                <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                <StyledListItemText index={index} variant="body1">
                  {t(item.labelKey)}
                </StyledListItemText>
              </StyledListItemButton>
            ))}
          </StyledList>
        </StyledDrawer>
      )}
    </>
  );
};

AppSideNavbar.propTypes = {
  isVisible: PropTypes.bool,
};

export default AppSideNavbar;
