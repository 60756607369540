/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import UserMessage from './userMessage.component';
import ResponseMessage from './responseMessage.component';
import { Stack, IconButton, styled } from '@mui/material';
import ReferenceDocuments from './referenceDocuments.component';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import ConfirmationDialog from '#components/confirmationDialog';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '#contexts/userContext';

const ChatMessageStack = styled(Stack)(({ theme }) => ({
  padding: 1,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const ChatMessage = ({ message, onReferenceDocumentClick, onDelete }) => {
  const { t } = useTranslation();
  const { hasPermission } = useUserContext();

  const [showActions, setShowActions] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const {
    progressStatus,
    userMessage,
    response,
    messageDateTime,
    responseDateTime,
    isResponseComplete,
    isResponseError,
    messageReferenceDocuments = [],
  } = message;

  const openDeleteConfirmation = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteMessage = () => {
    onDelete(message.id);
    setDeleteDialogOpen(false);
  };

  return (
    <ChatMessageStack
      spacing={1}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      selected={showActions}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
      >
        <UserMessage userMessage={userMessage} messageTime={messageDateTime} />
        {hasPermission('qna:conversation:message.delete') && showActions && (
          <IconButton sx={{ padding: 0 }} onClick={openDeleteConfirmation}>
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        )}
      </Stack>
      <ResponseMessage
        progressStatus={progressStatus}
        response={response}
        messageTime={responseDateTime}
        isComplete={isResponseComplete}
        isError={isResponseError}
      />
      {messageReferenceDocuments.length > 0 && (
        <ReferenceDocuments
          documents={messageReferenceDocuments}
          onDocumentClick={onReferenceDocumentClick}
        />
      )}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title={t('components.chatMessage.actions.delete.title')}
        messageLine1={t('components.chatMessage.actions.delete.messageLine1')}
        messageLine2={t('components.chatMessage.actions.delete.messageLine2')}
        onYesClick={handleDeleteMessage}
        onNoClick={handleCloseDeleteDialog}
      />
    </ChatMessageStack>
  );
};

export default ChatMessage;

ChatMessage.propTypes = {
  message: PropTypes.any,
  generatingResponse: PropTypes.bool,
  onReferenceDocumentClick: PropTypes.func,
  onDelete: PropTypes.func,
};
