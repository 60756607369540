/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { coreApiHttpClient } from './httpClients.';

const dataSourceApi = {
  getTenantDataSources: async (tenantId) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/dataSources`,
    );
    const { data } = response;
    return data;
  },

  getDataSources: async (
    tenantId,
    fileCollectionId,
    includeChildRecords = false,
  ) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/dataSources?includeChildRecords=${includeChildRecords}`,
    );
    const { data } = response;
    return data;
  },

  getDataSource: async (
    tenantId,
    fileCollectionId,
    dataSourceId,
    includeChildRecords = false,
  ) => {
    const response = await coreApiHttpClient.get(
      `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/dataSources/${dataSourceId}?includeChildRecords=${includeChildRecords}`,
    );
    const { data } = response;
    return data;
  },

  saveDataSource: async (tenantId, fileCollectionId, dataSource) => {
    let response;
    if (dataSource.id) {
      response = await coreApiHttpClient.put(
        `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/dataSources/${dataSource.id}`,
        dataSource,
      );
    } else {
      response = await coreApiHttpClient.post(
        `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/dataSources`,
        dataSource,
      );
    }
    const { data } = response;
    return data;
  },

  deleteDataSource: async (tenantId, fileCollectionId, dataSourceId) => {
    await coreApiHttpClient.delete(
      `/api/tenants/${tenantId}/fileCollections/${fileCollectionId}/dataSources/${dataSourceId}`,
    );
  },
};

export default dataSourceApi;
