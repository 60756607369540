/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const Record = ({ open, title, oldRecord, newRecord, onCloseClick }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onCloseClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText component={'div'}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h4">{'Old Record:'}</Typography>
              {oldRecord && (
                <div
                  style={{
                    fontFamily: 'Consolas, monospace',
                    backgroundColor: 'lightgrey',
                    borderRadius: '10px',
                    padding: '16px',
                  }}
                >
                  {Object.entries(oldRecord).map(([key, value]) => (
                    <Typography variant="body1" key={key}>
                      <strong>{key}:</strong> {value?.toString()}
                    </Typography>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4">{'New Record:'}</Typography>
              {newRecord && (
                <div
                  style={{
                    fontFamily: 'Consolas, monospace',
                    backgroundColor: 'lightgrey',
                    borderRadius: '10px',
                    padding: '16px',
                  }}
                >
                  {Object.entries(newRecord).map(([key, value]) => (
                    <Typography
                      variant="body1"
                      key={key}
                      sx={{
                        fontFamily: 'Consolas, monospace',
                        backgroundColor: 'lightgrey',
                      }}
                    >
                      <strong>{key}:</strong> {value.toString()}
                    </Typography>
                  ))}
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClick}>
          {t('components.auditRecord.actions.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Record.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  oldRecord: PropTypes.object,
  newRecord: PropTypes.object,
  messageLine2: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default Record;
