import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ConversationList from '#components/conversationList/conversationList.component';
import QnAOptions from '#components/qnaOptions/qnaOptions.component';
import { useUserContext } from '#contexts/userContext';

const RightPanel = ({
  appHeaderHeight,
  qnaOptions,
  viewOptions,
  conversation,
  conversations = [],
  onConversationChange,
  onQnAOptionsChange,
  onDeleteConversation,
  onViewOptionsChange,
}) => {
  const { hasPermission } = useUserContext();
  const { t } = useTranslation();

  const [maxPanelSize, setMaxPanelSize] = useState();

  const [maxComponentSize, setMaxComponentSize] = useState();

  useEffect(() => {
    setMaxPanelSize(window.innerHeight - appHeaderHeight);
    setMaxComponentSize(
      (window.innerHeight - appHeaderHeight) /
        (viewOptions.viewConversations + viewOptions.viewSearchOptions),
    );
  }, [appHeaderHeight, viewOptions]);

  const closeConversationView = () => {
    onViewOptionsChange({
      ...viewOptions,
      viewConversations: false,
    });
  };

  const closeSearchOptionsView = () => {
    onViewOptionsChange({
      ...viewOptions,
      viewSearchOptions: false,
    });
  };

  return (
    <Stack
      width={{ xs: '90%', md: '25%' }}
      spacing={1}
      sx={{
        maxHeight: `${maxPanelSize - 38}px`,
        height: `${maxPanelSize - 38}px`,
      }}
    >
      {viewOptions.viewConversations && hasPermission('qna:conversation') && (
        <Card
          variant="outlined"
          sx={{
            maxHeight: `${maxComponentSize}px`,
            height: `${maxComponentSize}px`,
          }}
        >
          <CardHeader
            title={t('pages.qna.rightPanel.tabs.conversationList')}
            action={
              <IconButton size="small" onClick={closeConversationView}>
                <Clear fontSize="small" />
              </IconButton>
            }
          />
          <Divider />
          <CardContent
            sx={{
              overflowY: 'auto',
              maxHeight: `${maxComponentSize - 58}px`,
              height: `${maxComponentSize - 58}px`,
              padding: 0,
            }}
          >
            <ConversationList
              maxHeight={maxPanelSize}
              conversations={conversations}
              conversation={conversation}
              onChange={onConversationChange}
              onDeleteConversation={onDeleteConversation}
            />
          </CardContent>
        </Card>
      )}
      {viewOptions.viewSearchOptions && hasPermission('qna:options') && (
        <Card
          variant="outlined"
          sx={{
            maxHeight: `${maxComponentSize}px`,
            height: `${maxComponentSize}px`,
          }}
        >
          <CardHeader
            title={t('pages.qna.rightPanel.tabs.searchOptions')}
            action={
              <IconButton size="small" onClick={closeSearchOptionsView}>
                <Clear fontSize="small" />
              </IconButton>
            }
          />
          <Divider />
          <CardContent
            sx={{
              overflowY: 'auto',
              maxHeight: `${maxComponentSize - 58}px`,
              height: `${maxComponentSize - 58}px`,
              padding: 0,
            }}
          >
            <QnAOptions qnaOptions={qnaOptions} onChange={onQnAOptionsChange} />
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

RightPanel.propTypes = {
  appHeaderHeight: PropTypes.number,
  conversation: PropTypes.object,
  conversations: PropTypes.array,
  qnaOptions: PropTypes.object,
  viewOptions: PropTypes.object,
  onConversationChange: PropTypes.func,
  onQnAOptionsChange: PropTypes.func,
  onViewOptionsChange: PropTypes.func,
  onDeleteConversation: PropTypes.func,
};

export default RightPanel;
