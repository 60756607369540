/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { createTheme } from '@mui/material';
import palette from './palette';
import typography from '../typography';
import fontWeights from '../fontWeights';
import {
  muiAlert,
  muiButton,
  muiOutlinedInput,
  muiSelect,
  muiTableComponents,
} from '../components';

export const themeOptions = {
  fontWeights,
  palette,
  typography,
  components: {
    MuiButton: muiButton,
    MuiOutlinedInput: muiOutlinedInput,
    MuiSelect: muiSelect,
    MuiAlert: muiAlert,
    MuiTable: muiTableComponents.muiTable,
    MuiTableContainer: muiTableComponents.muiTableContainer,
    MuiTableHead: muiTableComponents.muiTableHead,
    MuiTableCell: muiTableComponents.muiTableCell,
    MuiTableRow: muiTableComponents.muiTableRow,
  },
};

const speciphicTheme = createTheme(themeOptions);

export default speciphicTheme;
