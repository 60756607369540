/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectField from '#components/selectField';
import BooleanField from '#components/booleanField';
import { useUserContext } from '#contexts/userContext';

const QnAOptions = ({ qnaOptions = {}, onChange }) => {
  const { hasPermission } = useUserContext();
  const { t } = useTranslation();

  const llmOptions = [
    {
      value: 'azureopenai',
      label: t('components.qnaOptions.llmOptions.azureOpenAi'),
    },
    { value: 'openai', label: t('components.qnaOptions.llmOptions.openAi') },
  ];

  const onLlmChange = (value) => {
    onChange({ ...qnaOptions, llm: value });
  };

  const onConversationModeChange = (checked) => {
    onChange({ ...qnaOptions, conversationMode: checked });
  };

  const onStreamModeChange = (checked) => {
    onChange({ ...qnaOptions, streamMode: checked });
  };

  return (
    <Grid container spacing={1} justifyContent={'center'}>
      <Grid item xs={11}>
        <SelectField
          value={qnaOptions.llm}
          label={t('components.qnaOptions.fields.llm')}
          options={llmOptions}
          onChange={onLlmChange}
          disabled={!hasPermission('qna:options:change')}
        />
      </Grid>
      <Grid item xs={11}>
        <BooleanField
          label={t('components.qnaOptions.fields.conversationMode')}
          value={qnaOptions.conversationMode || false}
          required
          onChange={onConversationModeChange}
          disabled={!hasPermission('qna:options:change')}
        />
      </Grid>
      <Grid item xs={11}>
        <BooleanField
          label={t('components.qnaOptions.fields.streamMode')}
          value={qnaOptions.streamMode || false}
          required
          onChange={onStreamModeChange}
          disabled={!hasPermission('qna:options:change')}
        />
      </Grid>
    </Grid>
  );
};

QnAOptions.propTypes = {
  qnaOptions: PropTypes.object,
  onChange: PropTypes.func,
};

export default QnAOptions;
