/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import PropTypes from 'prop-types';
import SelectField from '#components/selectField';
import {
  Grid,
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import NumberField from '#components/numberField';
import TextField from '#components/textField';
import BooleanField from '#components/booleanField';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { parametersApi } from '#services/apis';
import { useToastContext } from '#contexts/providers/toast.provider';
import defaults from './defaultValues';

const LLM_OPTIONS = ['openai', 'azureopenai'];
const LONG_MESSAGE_STRATEGY_OPTIONS = [
  'TRUNCATE_PREFER_HISTORY',
  'TRUNCATE_PREFER_CONTEXT',
  'THROW_ERROR',
];
const RETRIEVAL_STRATEGY_OPTIONS = [
  'KEYWORD_BM25',
  'SEMANTIC_EMBEDDINGS',
  'COMPOSITE_BM25_EMBEDDING',
];
const RERANKING_STRATEGY_OPTIONS = ['NONE', 'TRANSFORMER'];
const EMBEDDING_STRATEGY_OPTIONS = [
  'NONE',
  'OPENAI',
  'AZURE_OPENAI',
  'TRANSFORMER',
];

const SearchParameters = ({
  tenantId,
  fileCollectionId,
  editable = true,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const { pushToast } = useToastContext();

  const [open, setOpen] = useState(true);
  const [searchParameters, setSearchParameters] = useState(
    defaultValues || defaults,
  );
  const [prevValues, setPrevValues] = useState(defaultValues || defaults);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [hasChanged, setHasChanged] = useState(defaults === undefined);
  const [loading, setLoading] = useState(false);

  const handleChange = (fieldName, value) => {
    setSearchParameters({ ...searchParameters, [fieldName]: value });
    setHasChanged(true);
  };

  const handleCancelClick = () => {
    setErrors({});
    setSearchParameters({ ...prevValues });
    setHasChanged(false);
  };

  const handleSaveClick = async () => {
    const errors = {};
    let hasError = false;
    setError();
    if (!searchParameters['defaultLlm']) {
      errors['defaultLlm'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (!searchParameters['queryEmbeddingStrategy']) {
      errors['queryEmbeddingStrategy'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (!searchParameters['documentRetrievalStrategy']) {
      errors['documentRetrievalStrategy'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (!searchParameters['documentRerankingStrategy']) {
      errors['documentRerankingStrategy'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      searchParameters['documentRerankingStrategy'] === 'TRANSFORMER' &&
      !searchParameters['documentRerankingModel']
    ) {
      errors['documentRerankingModel'] = t(
        'components.searchParameters.errors.required',
        {
          field: t('components.searchParameters.fields.documentRerankingModel'),
        },
      );
      hasError = true;
    }
    if (!searchParameters['chatCompletionContextLongMessageStrategy']) {
      errors['chatCompletionContextLongMessageStrategy'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      searchParameters['queryEmbeddingStrategy'] === 'OPENAI' &&
      !searchParameters['openAiCompletionModel']
    ) {
      errors['openAiCompletionModel'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      searchParameters['queryEmbeddingStrategy'] === 'AZURE_OPENAI' &&
      !searchParameters['azureOpenAiCompletionDeploymentName']
    ) {
      errors['azureOpenAiCompletionDeploymentName'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      searchParameters['queryEmbeddingStrategy'] === 'AZURE_OPENAI' &&
      !searchParameters['azureOpenAiCompletionModel']
    ) {
      errors['azureOpenAiCompletionModel'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }
    if (
      searchParameters['queryEmbeddingStrategy'] === 'TRANSFORMER' &&
      !searchParameters['transformerEmbeddingModel']
    ) {
      errors['transformerEmbeddingModel'] = t(
        'components.searchParameters.errors.selecAValue',
      );
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      setErrors({});
      try {
        await parametersApi.saveSearchParameters(
          tenantId,
          fileCollectionId,
          searchParameters,
        );
        pushToast({
          message: t('components.searchParameters.toasts.saved'),
          severity: 'success',
        });
        setPrevValues({ ...searchParameters });
        setHasChanged(false);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t('components.searchParameters.header')}
        subheader={error && <Alert severity="error">{error.message}</Alert>}
        action={
          <ButtonGroup>
            <IconButton
              onClick={() => setOpen(!open)}
              aria-label="expand"
              size="small"
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </ButtonGroup>
        }
      />
      <Divider />
      <Collapse in={open}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t('components.searchParameters.fields.defaultLlm')}
                value={searchParameters.defaultLlm}
                options={LLM_OPTIONS}
                required
                onChange={(value) => handleChange('defaultLlm', value)}
                disabled={!editable}
                error={errors['defaultLlm']}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.searchParameters.fields.sections.chatCompletion',
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.openAiCompletionModel',
                )}
                value={searchParameters.openAiCompletionModel}
                onChange={(value) =>
                  handleChange('openAiCompletionModel', value)
                }
                required
                disabled={!editable}
                error={errors['openAiCompletionModel']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.azureOpenAiCompletionDeploymentName',
                )}
                value={searchParameters.azureOpenAiCompletionDeploymentName}
                onChange={(value) =>
                  handleChange('azureOpenAiCompletionDeploymentName', value)
                }
                required
                disabled={!editable}
                error={errors['azureOpenAiCompletionDeploymentName']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.azureOpenAiCompletionModel',
                )}
                value={searchParameters.azureOpenAiCompletionModel}
                onChange={(value) =>
                  handleChange('azureOpenAiCompletionModel', value)
                }
                disabled={!editable}
                required
                error={errors['azureOpenAiCompletionModel']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.chatCompletionMaxTokens',
                )}
                value={searchParameters.chatCompletionMaxTokens}
                min={1}
                step={1}
                onChange={(value) =>
                  handleChange('chatCompletionMaxTokens', value)
                }
                disabled={!editable}
                error={errors['chatCompletionMaxTokens']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.chatCompletionNumberOfCompletions',
                )}
                value={searchParameters.chatCompletionNumberOfCompletions}
                min={1}
                step={1}
                max={3}
                onChange={(value) =>
                  handleChange('chatCompletionNumberOfCompletions', value)
                }
                disabled={!editable}
                error={errors['chatCompletionNumberOfCompletions']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.chatCompletionFrequencyPenalty',
                )}
                value={searchParameters.chatCompletionFrequencyPenalty}
                min={0}
                step={0.1}
                max={1}
                onChange={(value) =>
                  handleChange('chatCompletionFrequencyPenalty', value)
                }
                disabled={!editable}
                error={errors['chatCompletionFrequencyPenalty']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.chatCompletionPresencePenalty',
                )}
                value={searchParameters.chatCompletionPresencePenalty}
                min={0}
                step={0.1}
                max={1}
                onChange={(value) =>
                  handleChange('chatCompletionPresencePenalty', value)
                }
                disabled={!editable}
                error={errors['chatCompletionPresencePenalty']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.chatCompletionTemperature',
                )}
                value={searchParameters.chatCompletionTemperature}
                min={0}
                step={0.1}
                max={1}
                onChange={(value) =>
                  handleChange('chatCompletionTemperature', value)
                }
                disabled={!editable}
                error={errors['chatCompletionTemperature']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.chatCompletionTopP',
                )}
                value={searchParameters.chatCompletionTopP}
                min={0.1}
                step={0.1}
                max={1}
                onChange={(value) => handleChange('chatCompletionTopP', value)}
                disabled={!editable}
                error={errors['chatCompletionTopP']}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.searchParameters.fields.sections.queryEmbeddings',
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.searchParameters.fields.queryEmbeddingStrategy',
                )}
                value={searchParameters.queryEmbeddingStrategy}
                options={EMBEDDING_STRATEGY_OPTIONS}
                required
                onChange={(value) =>
                  handleChange('queryEmbeddingStrategy', value)
                }
                disabled={!editable}
                error={errors['queryEmbeddingStrategy']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.openAiEmbeddingModel',
                )}
                value={searchParameters.openAiEmbeddingModel}
                onChange={(value) =>
                  handleChange('openAiEmbeddingModel', value)
                }
                disabled={!editable}
                error={errors['openAiEmbeddingModel']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.azureOpenAiEmbeddingDeploymentName',
                )}
                value={searchParameters.azureOpenAiEmbeddingDeploymentName}
                onChange={(value) =>
                  handleChange('azureOpenAiEmbeddingDeploymentName', value)
                }
                disabled={!editable}
                error={errors['azureOpenAiEmbeddingDeploymentName']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.azureOpenAiEmbeddingModel',
                )}
                value={searchParameters.azureOpenAiEmbeddingModel}
                onChange={(value) =>
                  handleChange('azureOpenAiEmbeddingModel', value)
                }
                disabled={!editable}
                error={errors['azureOpenAiEmbeddingModel']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.transformerEmbeddingModel',
                )}
                value={searchParameters.transformerEmbeddingModel}
                onChange={(value) =>
                  handleChange('transformerEmbeddingModel', value)
                }
                disabled={!editable}
                error={errors['transformerEmbeddingModel']}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.searchParameters.fields.sections.contextCreation',
                )}
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t(
                  'components.searchParameters.fields.chatCompletionSystemPrompt',
                )}
                value={searchParameters.chatCompletionSystemPrompt}
                multiline
                onChange={(value) =>
                  handleChange('chatCompletionSystemPrompt', value)
                }
                disabled={!editable}
                error={errors['chatCompletionSystemPrompt']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t(
                  'components.searchParameters.fields.condensePromptContextSystemPrompt',
                )}
                value={searchParameters.condensePromptContextSystemPrompt}
                multiline
                onChange={(value) =>
                  handleChange('condensePromptContextSystemPrompt', value)
                }
                disabled={!editable}
                error={errors['condensePromptContextSystemPrompt']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t(
                  'components.searchParameters.fields.chatCompletionUserPromptTemplate',
                )}
                value={searchParameters.chatCompletionUserPromptTemplate}
                multiline
                onChange={(value) =>
                  handleChange('chatCompletionUserPromptTemplate', value)
                }
                disabled={!editable}
                error={errors['chatCompletionUserPromptTemplate']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t(
                  'components.searchParameters.fields.chatCompletionUserCondenseHistoryPromptTemplate',
                )}
                value={
                  searchParameters.chatCompletionUserCondenseHistoryPromptTemplate
                }
                multiline
                onChange={(value) =>
                  handleChange(
                    'chatCompletionUserCondenseHistoryPromptTemplate',
                    value,
                  )
                }
                disabled={!editable}
                error={
                  errors['chatCompletionUserCondenseHistoryPromptTemplate']
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.searchParameters.fields.chatCompletionContextIncludeHistory',
                )}
                value={searchParameters.chatCompletionContextIncludeHistory}
                onChange={(value) =>
                  handleChange('chatCompletionContextIncludeHistory', value)
                }
                disabled={!editable}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.chatCompletionContextMaxHistory',
                )}
                value={searchParameters.chatCompletionContextMaxHistory}
                min={0}
                step={1}
                max={1000}
                onChange={(value) =>
                  handleChange('chatCompletionContextMaxHistory', value)
                }
                disabled={!editable}
                error={errors['chatCompletionContextMaxHistory']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <BooleanField
                label={t(
                  'components.searchParameters.fields.chatCompletionContextCondenseHistory',
                )}
                value={searchParameters.chatCompletionContextCondenseHistory}
                onChange={(value) =>
                  handleChange('chatCompletionContextCondenseHistory', value)
                }
                disabled={!editable}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.searchParameters.fields.chatCompletionContextLongMessageStrategy',
                )}
                value={
                  searchParameters.chatCompletionContextLongMessageStrategy
                }
                options={LONG_MESSAGE_STRATEGY_OPTIONS}
                required
                onChange={(value) =>
                  handleChange(
                    'chatCompletionContextLongMessageStrategy',
                    value,
                  )
                }
                disabled={!editable}
                error={errors['chatCompletionContextLongMessageStrategy']}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider>
                {t(
                  'components.searchParameters.fields.sections.documentRetrieval',
                )}
              </Divider>
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.searchParameters.fields.documentRetrievalStrategy',
                )}
                value={searchParameters.documentRetrievalStrategy}
                options={RETRIEVAL_STRATEGY_OPTIONS}
                required
                onChange={(value) =>
                  handleChange('documentRetrievalStrategy', value)
                }
                disabled={!editable}
                error={errors['documentRetrievalStrategy']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <NumberField
                label={t(
                  'components.searchParameters.fields.documentRetrievalMaxSize',
                )}
                value={searchParameters.documentRetrievalMaxSize}
                min={1}
                step={1}
                max={100}
                onChange={(value) =>
                  handleChange('documentRetrievalMaxSize', value)
                }
                disabled={!editable}
                error={errors['documentRetrievalMaxSize']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <SelectField
                label={t(
                  'components.searchParameters.fields.documentRerankingStrategy',
                )}
                value={searchParameters.documentRerankingStrategy}
                options={RERANKING_STRATEGY_OPTIONS}
                required
                onChange={(value) =>
                  handleChange('documentRerankingStrategy', value)
                }
                disabled={!editable}
                error={errors['documentRerankingStrategy']}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                label={t(
                  'components.searchParameters.fields.documentRerankingModel',
                )}
                value={searchParameters.documentRerankingModel}
                onChange={(value) =>
                  handleChange('documentRerankingModel', value)
                }
                disabled={!editable}
                error={errors['documentRerankingModel']}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {loading ? (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <CircularProgress />
          </CardActions>
        ) : (
          editable &&
          hasChanged && (
            <CardActions sx={{ justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleCancelClick}>
                {t('components.searchParameters.actions.cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={handleSaveClick}
              >
                {t('components.searchParameters.actions.save')}
              </Button>
            </CardActions>
          )
        )}
      </Collapse>
    </Card>
  );
};

SearchParameters.propTypes = {
  tenantId: PropTypes.string,
  fileCollectionId: PropTypes.string,
  editable: PropTypes.bool,
  defaultValues: PropTypes.object,
  onChange: PropTypes.func,
};

export default SearchParameters;
