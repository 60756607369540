/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { CircularProgress, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

const SelectBox = ({ options = [], value, loading, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Select value={value} onChange={handleChange}>
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectBox.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectBox;
