/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import DescriptionIcon from '@mui/icons-material/Description';
import { StyledIconButton } from './referenceDocuments.styled';
import { Tooltip } from '@mui/material';

const ReferenceDocuments = ({ documents = [], onDocumentClick }) => {
  return (
    <Stack direction={'row'} spacing={0} paddingLeft={6}>
      {documents.map((document, idx) => (
        <StyledIconButton
          key={`${document.fileName}-${idx}`}
          onClick={() => onDocumentClick(document)}
        >
          <Tooltip title={document.fileName} placement="top">
            <DescriptionIcon />
          </Tooltip>
        </StyledIconButton>
      ))}
    </Stack>
  );
};

ReferenceDocuments.propTypes = {
  documents: PropTypes.array,
  onDocumentClick: PropTypes.func,
};

export default ReferenceDocuments;
