/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import NumberField from '#components/numberField';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MultipleSelectField from '#components/muiltipleSelectField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';

const ACTION_OPTIONS = ['CREATE', 'UPDATE', 'DELETE'];
const RECORD_TYPES = ['FILECOLLECTION', 'DATASOURCE', 'ROLE'];

const Filters = ({ onFilterChange, defaultFilters }) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState(defaultFilters);

  const handleOnFilterChange = () => {
    onFilterChange(filters);
  };

  const handleChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t('components.auditFilters.headers')} />
      <Divider />
      <CardContent>
        <Grid container direction={'column'} spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <MultipleSelectField
              label={t('components.auditFilters.fields.action')}
              value={filters.actions}
              options={ACTION_OPTIONS}
              onChange={(value) => handleChange('actions', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MultipleSelectField
              label={t('components.auditFilters.fields.recordTypes')}
              value={filters.recordTypes}
              options={RECORD_TYPES}
              onChange={(value) => handleChange('recordTypes', value)}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <NumberField
              label={t('components.auditFilters.fields.maxRecords')}
              value={filters.maxRecords}
              min={1}
              step={1}
              max={5000}
              onChange={(value) => handleChange('maxRecords', value)}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('components.auditFilters.fields.startDate')}
                value={filters.startDate}
                onChange={(value) => handleChange('startDate', value)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('components.auditFilters.fields.endDate')}
                value={filters.endDate}
                onChange={(value) => handleChange('endDate', value)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOnFilterChange}
            >
              {t('components.auditFilters.actions.apply')}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Filters.propTypes = {
  onFilterChange: PropTypes.func,
  defaultFilters: PropTypes.object,
};

export default Filters;
